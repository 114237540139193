import React from 'react'
import Typography from '@mui/material/Typography';
import pagedetail from '../menulist/Live.json'
import { CardHeader, CardMedia, Card, CardContent, Avatar, Backdrop, Slide, Dialog, Snackbar, CardActionArea } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";
import moment from 'moment';

import LiveComponent from './livecomponent/viewlive'

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const LiveStream = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [rootArr, setRootArr] = React.useState([]);
    const [instantview, setInstantview] = React.useState([]);

    const [obj, setObj] = React.useState(null);
    const [dialog, setDialog] = React.useState(false);

    const His = useHistory()

    React.useEffect(() => {
        // function handleWindowResize() {
        //   setRealwidth(window.innerWidth);
        // }
        if (lang == 'th') {
          setPage('T-POP ไลฟ์')
        } else {
          setPage('T-POP LIVE')
        }
        // window.addEventListener('resize', handleWindowResize);
        setLoad(true)
        fetch('https://cpxdevweb.runasp.net/tpop/getlive', {
          method: 'post'
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.length > 0) {
              setRootArr(data)
              setTimeout(() => {
                fetch('https://cpxdevweb.runasp.net/tpop/instantlistartists', {
                    method: 'post'
                })
                    .then((response) => response.json())
                    .then((d) => {
                    if (d.length > 0) {
                        setInstantview(d)
                    }
                    });
              }, 300);
            }
            setLoad(false)
          });
        return () => {
        //   window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

      const getLink = (yID) => {
        let link = ""
        if (instantview.length > 0 && instantview.filter(x => x.yId == yID).length > 0) {
          if (instantview.filter(x => x.yId == yID).length == 1)
          {
            link = instantview.filter(x => x.yId == yID)[0].artId
          }
        }
        return link
    }

React.useEffect(() => {
  const current = document.documentElement.scrollTop
  window.scrollTo(0, document.documentElement.scrollHeight);
  window.scrollTo(0, 0);
  window.scrollTo(0, current);
}, [dark])

const changep = (artid) => {
  setDialog(null)
  setLoad(true)
  setTimeout(() => His.push('/artist/' + artid), 700)
}

      const subheaderformat = (status, channel, date, channelid) => {
        let respo = (<p></p>)
        const artID = getLink(channelid)
        switch(status){
            case 'upcoming': {
                if (lang == 'th') {
                  if (artID != "") {
                    respo = (<p>{"อัปโหลดโดย \""}<a style={{color:'#0a05f2'}} onClick={() => changep(artID)}>{channel}</a>{"\" มีการกำหนดเวลาการสตรีมสดเมื่อวันที่ " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                  } else {
                    respo = (<p>{"อัปโหลดโดย \"" + channel + "\" มีการกำหนดเวลาการสตรีมสดเมื่อวันที่ " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                  }
                } else {
                  if (artID != "") {
                    respo = (<p>{"Uploaded by \""}<a style={{color:'#0a05f2'}} onClick={() => changep(artID)}>{channel}</a>{"\" Live streaming scheduled since " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                  } else {
                    respo = (<p>{"Uploaded by \"" + channel + "\" Live streaming scheduled since " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                  }
                  
                }
                break;
            }
            case 'live': {
              if (lang == 'th') {
                if (artID != "") {
                  respo = (<p>{"อัปโหลดโดย \""}<a style={{color:'#0a05f2'}} onClick={() => changep(artID)}>{channel}</a>{"\" เริ่มสตรีมสดตั้งแต่วันที่ " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                } else {
                  respo = (<p>{"อัปโหลดโดย \"" + channel + "\" เริ่มสตรีมสดตั้งแต่วันที่ " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                }
              } else {
                if (artID != "") {
                  respo = (<p>{"Uploaded by \""}<a style={{color:'#0a05f2'}} onClick={() => changep(artID)}>{channel}</a>{"\" Live streaming since " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                } else {
                  respo = (<p>{"Uploaded by \"" + channel + "\" Live streaming since " + moment.utc(date).local().lang(lang).format('DD MMMM YYYY HH:mm:ss')}</p>)
                }
                
              }
                break;
            }
            default: 
              return respo
        }
        return respo
      }

const onClose = () => {
    setDialog(false)
    setObj(null)
}
const onOpen = (item) => {
    setObj(item)
    setDialog(true)
}

if (rootArr.length == 0) {
    return (
        <Backdrop
        sx={{ zIndex: -1, position: 'fixed', backgroundColor: (dark ? "#02012b":"#fff") }}
        open={true}
        className={dark ? 'text-light' : ''}
        >
        {lang == 'th' ? 'ไม่พบการถ่ายทอดสดในขณะนี้' : 'Sorry, Could not be found any Live streaming'}
      </Backdrop>
    )
}


    return ( 
        <>
        <CardHeader av title={(<h3 className={dark ? 'text-light' : ''}>{pagedetail[lang].title}</h3>)} subheader={<p className={dark ? 'text-light' : ''}>{pagedetail[lang].desc}</p>} />
        <div className='text-left'>
          <div className='container col-12 justify-content-center'>
            <div className='row'>
            {rootArr.map((item) => (
               <Card key={item.id} className='col-12 pb-3 mb-3' data-aos="zoom-in-down">
                <CardActionArea>
                    <CardHeader
                        title={(<h5>{item.snippet.title}</h5>)}
                        subheader={subheaderformat(item.snippet.liveBroadcastContent, item.snippet.channelTitle, item.snippet.publishedAt, item.snippet.channelId)}
                        avatar={
                            item.snippet.liveBroadcastContent == 'live' ? <Avatar className='w-100' src='https://i.giphy.com/media/wvP4x67EnIhWiCztAf/giphy.webp' />
                            : null
                        }
                    />
                    <div className='container' onClick={() => onOpen(item)}>
                    <CardMedia
                        component="img"
                        image={item.snippet.thumbnails.maxres.url}
                        alt={item.snippet.title}
                    />
                    </div>
                </CardActionArea>
               </Card>
              ))}
            </div>
          </div>
        </div>

        <Dialog
          fullScreen
          open={dialog}
          TransitionComponent={Transition}
          className={dark ? 'darkdialog' : ''}
        >

            {
                obj != null && <LiveComponent dark={dark} onClose={() => onClose()} livevideoobj={obj} lang={lang} />
            }
      </Dialog>
      
      </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(LiveStream);