import React from 'react'
import pagedetail from '../menulist/Contact.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, CardActionArea, ListItemIcon, List, ListItem, ListItemText, ListItemButton } from '@mui/material';

import { Telegram, Email } from '@mui/icons-material';

import {
  useParams,
  useHistory
} from "react-router-dom";

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

const Con = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    // const [langselect, setLang] = React.useState('en');
    const History = useHistory();
  
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      sessionStorage.removeItem('artlistprevious')
      if (lang == 'th') {
        setPage('ติดต่อเรา')
      } else {
        setPage('Contact us')
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.runasp.net/tpop/status')
        .then((response) => response.text())
        .then((data) => {
          setLoad(false)
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    // React.useEffect(() => {
    //   setLang(lang)
    // }, [lang]);

    if (load) return null
    return ( 
      <>
      <CardHeader className={dark ? 'text-light' : ''} title={pagedetail[lang].title} />
        <div className='mt-2'>
        {
            pagedetail[lang].list.map((item, i) => (
                <List key={item.title}>
                    <ListItemButton onClick={() => window.open(item.link, '_blank')}className={dark ? 'text-light' : ''}>
                      <>
                      {
                        i == 0 ? (
                          <ListItemIcon className={dark ? 'text-light' : ''}>
                            <Email />
                          </ListItemIcon>
                        ) : i == 1 ? (
                          <ListItemIcon className={dark ? 'text-light' : ''}>
                            <Telegram />
                          </ListItemIcon>
                        ) : null
                      }
                      </>
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </List>
            ))
        }
        </div>
      </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Con);