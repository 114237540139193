import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import { setLoad, setLang, setDarkMode, setPage } from "../redux/action";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Typography,
  Skeleton,
  Card,
  Menu,
  LinearProgress,
  Backdrop,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  TextField,
  MenuItem,
  AvatarGroup,
  Fab,
  Divider,
  Button,
  Drawer,
  Box,
  FormControlLabel,
  Chip,
  Checkbox,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  ListItemText,
  CardMedia,
} from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import StarIcon from "@mui/icons-material/Star";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ReCAPTCHA from "react-google-recaptcha";
import { Carousel as MobileCarousel } from "react-responsive-carousel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import reportList from "../menulist/reportList.json";

const loadske = [0, 0, 0, 0, 0];

const ViewPost = ({
  dark,
  load,
  lang,
  currentPage,
  setLoad,
  setDark,
  setLang,
  setPage,
  login,
}) => {
  let { id } = useParams();
  const [ro, setReadOnly] = React.useState(false);
  const [list, setList] = React.useState(null);
  const [art, setArt] = React.useState([]);
  const [sbSet, setSB] = React.useState(0);
  const [likeload, setLikeLoad] = React.useState(false);
  const [likecomload, setLikecomLoad] = React.useState(false);
  const [width, setRealwidth] = React.useState(window.innerWidth);
  const [uploadState, setUp] = React.useState(false);
  const [inp, setInp] = React.useState("");
  const [addcomment, setCom] = React.useState(false);
  const recaptchaRef = React.createRef();
  const [key, setKey] = React.useState("");

  const [reporthook, setReportHook] = React.useState(null);
  const [report, setReportStructure] = React.useState({
    topic: "-",
    more: "",
  });

  const [reporthook2, setReportHook2] = React.useState(null);
  const [report2, setReportStructure2] = React.useState({
    topic: "-",
    more: "",
  });

  const his = useHistory();

  const setLike = (postidobj) => {
    if (likeload) {
      return;
    }
    if (login == null) {
      Swal.fire({
        title: "Please login to continue",
        text:
          lang == "th"
            ? "กรุณาเข้าสู่ระบบเพื่อกดชื่นชอบโพสต์นี้"
            : "Please login to like this post.",
        icon: "error",
      });
      return;
    }
    if (login != null && ro) {
      Swal.fire({
        title: "Permission Denied",
        text:
          lang == "th"
            ? "กรุณาลิงก์กับบัญชี Bluesky Account เพื่อใช้งานฟีเจอร์นี้"
            : "Please link to your Bluesky Social Account to continue.",
        icon: "warning",
      });
      return;
    }
    setLikeLoad(true);
    fetch("https://tpopcom.azurewebsites.net/api/like/setlikepoststatus", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: login.fromsystem.memId,
      },
      body: JSON.stringify({
        postId: postidobj.postId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLikeLoad(false);
        if (data.status == true) {
          let datax = list;
          if (data.res == true) {
            datax.post.likeCount += 1;
          } else {
            datax.post.likeCount -= 1;
          }
          datax.post.likestatus = data.res;
          setList(datax);
        } else {
          Swal.fire({
            title: "Error found",
            text: data.message,
            icon: "error",
          });
        }
      });
  };

  const setLikeComment = (postidobj, comid) => {
    if (likecomload) {
      return;
    }
    if (login == null) {
      Swal.fire({
        title: "Please login to continue",
        text:
          lang == "th"
            ? "กรุณาเข้าสู่ระบบเพื่อกดชื่นชอบโพสต์นี้"
            : "Please login to like this post.",
        icon: "error",
      });
      return;
    }
    if (login != null && ro) {
      Swal.fire({
        title: "Permission Denied",
        text:
          lang == "th"
            ? "กรุณาลิงก์กับบัญชี Bluesky Account เพื่อใช้งานฟีเจอร์นี้"
            : "Please link to your Bluesky Social Account to continue.",
        icon: "warning",
      });
      return;
    }
    setLikecomLoad(true);
    fetch("https://tpopcom.azurewebsites.net/api/like/setlikecommentstatus", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: login.fromsystem.memId,
      },
      body: JSON.stringify({
        postId: postidobj.postId,
        commentId: comid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLikecomLoad(false);
        if (data.status == true) {
          let datax = list;
          if (data.res == true) {
            datax.comments.filter(
              (x) => x.commentId == comid
            )[0].likeCount += 1;
          } else {
            datax.comments.filter(
              (x) => x.commentId == comid
            )[0].likeCount -= 1;
          }
          datax.comments.filter((x) => x.commentId == comid)[0].likestatus =
            data.res;
          setList(datax);
        } else {
          Swal.fire({
            title: "Error found",
            text: data.message,
            icon: "error",
          });
        }
      });
  };

  const FetchList = (mem) => {
    setList(null);
    fetch("https://tpopcom.azurewebsites.net/api/list/listComment", {
      method: "post",
      headers:
        mem != null
          ? {
              Authorization: mem,
              "Content-Type": "application/json",
            }
          : {
              "Content-Type": "application/json",
            },
      body: JSON.stringify({
        postId: atob(id),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          if (lang == "th") {
            setPage('ดูโพสต์ "' + data.post.text + '"');
          } else {
            setPage('View post of "' + data.post.text + '"');
          }
          setList(data);
        } else {
          his.push("/community");
        }
      });
  };

  const postcomment = (id, val) => {
    setCom(true);
    fetch("https://tpopcom.azurewebsites.net/api/uploadpost/createComment", {
      method: "put",
      headers:
        login != null
          ? {
              Authorization: login.fromsystem.memId,
              "Content-Type": "application/json",
            }
          : {
              "Content-Type": "application/json",
            },
      body: JSON.stringify({
        postId: id,
        text: val,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCom(false);
        if (data.status) {
          FetchList(login.fromsystem.memId);
        } else {
          Swal.fire({
            title: "Error found",
            text: data.message,
            icon: "error",
          });
        }
      });
  };

  const deletePost = (post) => {
    Swal.fire({
      title:
        lang == "th"
          ? "คุณต้องการลบกระทู้นี้หรือไม่?"
          : "Do you want to delete this article?",
      showDenyButton: true,
      confirmButtonText: lang == "th" ? "ลบ" : "Delete",
      denyButtonText: lang == "th" ? "ยกเลิก" : "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setList(null);
        fetch("https://tpopcom.azurewebsites.net/api/uploadpost/deletePost", {
          method: "delete",
          headers: {
            Authorization: login.fromsystem.memId,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            postId: post.postId,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status) {
              his.push("/community");
            } else {
              Swal.fire({
                title: "Error found",
                text: data.message,
                icon: "error",
              });
              FetchList(login.fromsystem.memId);
            }
          });
      }
    });
  };
  const deleteCom = (com) => {
    Swal.fire({
      title:
        lang == "th"
          ? "คุณต้องการลบความเห็นนี้หรือไม่?"
          : "Do you want to delete this comment?",
      showDenyButton: true,
      confirmButtonText: lang == "th" ? "ลบ" : "Delete",
      denyButtonText: lang == "th" ? "ยกเลิก" : "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          "https://tpopcom.azurewebsites.net/api/uploadpost/deleteComment",
          {
            method: "delete",
            headers: {
              Authorization: login.fromsystem.memId,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              postId: com.postId,
              commentId: com.commentId,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status) {
              FetchList(login.fromsystem.memId);
            } else {
              Swal.fire({
                title: "Error found",
                text: data.message,
                icon: "error",
              });
            }
          });
      }
    });
  };

  const uploadReport = (e) => {
    e.preventDefault();
    if (key == "") {
      Swal.fire({
        title: "Bot verification fail",
        text:
          lang == "th"
            ? "กรุณายืนยัน Bot verification ก่อนอัปโหลดรายงาน"
            : "Please verify Bot verification.",
        icon: "warning",
      });
      return;
    }
    if (report.topic == "-") {
      Swal.fire({
        title: "Report topic not selected",
        text:
          lang == "th"
            ? "กรุณาเลือกเหตุผลในการรายงาน"
            : "Please choose report reason.",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      title: "Final Confirmation",
      text:
        lang == "th"
          ? "คุณต้องการยืนยันการอัปโหลดหรือไม่"
          : "Do you want to upload this report?",
      icon: "question",
      footer:
        lang == "th"
          ? "หลังจากอัปโหลดรายงานนี้แล้วจะไม่สามารถดำเนินการแก้ไขได้"
          : "You cannot edit this report after uploaded.",
      showCancelButton: true,
      confirmButtonText: lang == "th" ? "ยืนยัน" : "Confirm",
      cancelButtonText: lang == "th" ? "ปิด" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        const reporth = reporthook;
        setUp(true);
        setReportHook(null);
        fetch("https://tpopcom.azurewebsites.net/api/report/createReport", {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            authorization: login.fromsystem.memId,
          },
          body: JSON.stringify({
            reportFrom: login.fromsystem.memId,
            reportTo: reporth.author.split("|")[1],
            reportPost: reporth.postId,
            reportHead: report.topic,
            reportDesc: report.more,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setUp(false);
            setReportStructure({
              topic: "-",
              more: "",
            });
            if (data.status == true) {
              setUp(false);
              Swal.fire({
                title: "Report successfully",
                text:
                  lang == "th"
                    ? "อัปโหลดรายงานแล้ว"
                    : "Uploaded report success",
                icon: "success",
              });
            } else {
              setUp(false);
              Swal.fire({
                title: "Something went wrong",
                text: data.message,
                icon: "error",
              });
            }
          });
      }
    });
  };
  const uploadReport2 = (e) => {
    e.preventDefault();
    if (key == "") {
      Swal.fire({
        title: "Bot verification fail",
        text:
          lang == "th"
            ? "กรุณายืนยัน Bot verification ก่อนอัปโหลดรายงาน"
            : "Please verify Bot verification.",
        icon: "warning",
      });
      return;
    }
    if (report2.topic == "-") {
      Swal.fire({
        title: "Report topic not selected",
        text:
          lang == "th"
            ? "กรุณาเลือกเหตุผลในการรายงาน"
            : "Please choose report reason.",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      title: "Final Confirmation",
      text:
        lang == "th"
          ? "คุณต้องการยืนยันการอัปโหลดหรือไม่"
          : "Do you want to upload this report?",
      icon: "question",
      footer:
        lang == "th"
          ? "หลังจากอัปโหลดรายงานนี้แล้วจะไม่สามารถดำเนินการแก้ไขได้"
          : "You cannot edit this report after uploaded.",
      showCancelButton: true,
      confirmButtonText: lang == "th" ? "ยืนยัน" : "Confirm",
      cancelButtonText: lang == "th" ? "ปิด" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        const reporth = reporthook2;
        setUp(true);
        setReportHook2(null);
        fetch("https://tpopcom.azurewebsites.net/api/report/createReport", {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            authorization: login.fromsystem.memId,
          },
          body: JSON.stringify({
            reportFrom: login.fromsystem.memId,
            reportTo: reporth.author.split("|")[1],
            reportPost: reporth.postId,
            reportComment: reporth.commentId,
            reportHead: report2.topic,
            reportDesc: report2.more,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            setUp(false);
            setReportStructure2({
              topic: "-",
              more: "",
            });
            if (data.status == true) {
              setUp(false);
              Swal.fire({
                title: "Report successfully",
                text:
                  lang == "th"
                    ? "อัปโหลดรายงานแล้ว"
                    : "Uploaded report success",
                icon: "success",
              });
            } else {
              setUp(false);
              Swal.fire({
                title: "Something went wrong",
                text: data.message,
                icon: "error",
              });
            }
          });
      }
    });
  };

  React.useEffect(() => {
    function handleWindowResize() {
      setRealwidth(window.innerWidth);
    }

    //AOS.init({ duration: 800 });
    window.addEventListener("resize", handleWindowResize);
    window.scrollTo({ top: 0, behavior: "smooth" });
    fetch("https://cpxdevweb.runasp.net/tpop/artistlist?lang=" + lang, {
      method: "post",
    })
      .then((response) => response.json())
      .then((data) => {
        setArt(data);
      });

    if (login != null) {
      setLoad(false);
      setReadOnly(false);
      FetchList(login.fromsystem.memId);
    } else {
      setLoad(false);
      FetchList(null);
    }
  }, []);
  return (
    <div style={{ marginBottom: 190 }}>
      <Snackbar
        open={uploadState}
        className="justify-content-center"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="info" variant="filled">
          <Typography variant="h6" className="mb-1">
            {lang == "th"
              ? "กำลังอัปโหลด กรุณารอสักครู่"
              : "Uploading, please wait"}
          </Typography>
          <LinearProgress />
        </Alert>
      </Snackbar>

      {list == null ? (
        <>
          <Card className="mt-3">
            <CardHeader
              title={<Skeleton variant="text" width="100%" />}
              subheader={<Skeleton variant="text" width="30%" />}
            />
            <CardContent>
              <Skeleton
                className="mb-2"
                variant="rounded"
                width="100%"
                height={300}
              />
              {loadske.map((item) => (
                <Skeleton variant="text" width="100%" sx={{ fontSize: 15 }} />
              ))}
            </CardContent>
            <CardActions>
              <IconButton size="small">
                <StarIcon /> <Skeleton variant="text" width={30} />
              </IconButton>
            </CardActions>
          </Card>
        </>
      ) : (
        <>
          <div style={{ marginTop: sbSet + 10 }} />
          <>
            {width < 800 ? (
              <PullToRefresh
                onRefresh={() =>
                  FetchList(
                    login != null && !ro ? login.fromsystem.memId : null
                  )
                }
                className="text-center"
                height="100%"
                displayName="">
                <Card className="mt-3 text-left">
                  <CardHeader
                    action={
                      <>
                        {login != null &&
                          list.post.author.split("|")[1] !=
                            login.fromsystem.memId && (
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => setReportHook(list.post)}>
                              <ReportProblemIcon />
                            </IconButton>
                          )}
                        {list.post.author.split("|")[1] !=
                          login.fromsystem.memId && (
                          <IconButton
                            size="large"
                            onClick={() => deletePost(list.post)}
                            color="inherit">
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </>
                    }
                    title={list.post.head}
                    subheader={moment(list.post.createdAt)
                      .lang(lang)
                      .local()
                      .format("DD MMMM YYYY HH:mm:ss")}
                  />
                  <CardContent>
                    <MobileCarousel showArrows showStatus={false}>
                      {list.post.img.map((img, i) => (
                        <CardMedia src={img} component="img" />
                      ))}
                    </MobileCarousel>
                    {list.post.artistTag.length > 0 && (
                      <div className="d-flex justify-content-start mb-2">
                        <Typography className="text-bold pt-1 mr-3">
                          {lang == "th"
                            ? "ศิลปินที่เกี่ยวข้อง: "
                            : "Related Artist: "}
                          {list.post.artistTag.map((id, i) =>
                            i == list.post.artistTag.length - 1 ? (
                              <span
                                className="text-info"
                                onClick={() =>
                                  window.open("/artist/" + id, "_blank")
                                }>
                                {
                                  art.filter((x) => x.artId == id)[0].artName[
                                    lang
                                  ]
                                }
                              </span>
                            ) : (
                              <span
                                className="text-info"
                                onClick={() =>
                                  window.open("/artist/" + id, "_blank")
                                }>
                                {
                                  art.filter((x) => x.artId == id)[0].artName[
                                    lang
                                  ]
                                }
                                ,{" "}
                              </span>
                            )
                          )}
                        </Typography>
                      </div>
                    )}
                    <Divider />
                    <Typography className="mt-2">{list.post.text}</Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      sx={{ fontSize: 18 }}
                      onClick={() => setLike(list.post)}>
                      <StarIcon />
                      &nbsp;
                      {likeload ? (
                        <Skeleton variant="text" width={30} />
                      ) : (
                        list.post.likeCount
                      )}
                    </IconButton>
                  </CardActions>
                </Card>
              </PullToRefresh>
            ) : (
              <div className="" style={{ paddingTop: 15 }}>
                <Card className="mt-3 text-left">
                  <CardHeader
                    action={
                      <>
                        {login != null &&
                          list.post.author.split("|")[1] !=
                            login.fromsystem.memId && (
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => setReportHook(list.post)}>
                              <ReportProblemIcon />
                            </IconButton>
                          )}
                        {list.post.author.split("|")[1] ==
                          login.fromsystem.memId && (
                          <IconButton
                            size="large"
                            onClick={() => deletePost(list.post)}
                            color="inherit">
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </>
                    }
                    title={list.post.head}
                    subheader={
                      (list.post.author.split("|")[1] == login.fromsystem.memId
                        ? lang == "th"
                          ? "โพสต์โดยคุณเมื่อ "
                          : "Posted by you in "
                        : lang == "th"
                        ? "โพสต์โดย " +
                          list.post.author.split("|")[0] +
                          " เมื่อ"
                        : "Posted by " +
                          list.post.author.split("|")[0] +
                          " in ") +
                      moment(list.post.createdAt)
                        .lang(lang)
                        .local()
                        .format("DD MMMM YYYY HH:mm:ss")
                    }
                  />
                  <CardContent className="col-12">
                    <MobileCarousel showArrows showStatus={false}>
                      {list.post.img.map((img, i) => (
                        <CardMedia className="w-50" src={img} component="img" />
                      ))}
                    </MobileCarousel>
                    {list.post.artistTag.length > 0 && (
                      <div className="d-flex justify-content-start mb-2">
                        <Typography className="text-bold pt-1 mr-3">
                          {lang == "th"
                            ? "ศิลปินที่เกี่ยวข้อง"
                            : "Related Artist"}
                        </Typography>
                        <AvatarGroup max={4} style={{ alignItems: "left" }}>
                          {list.post.artistTag.map((id) => (
                            <Avatar
                              onClick={() =>
                                window.open("/artist/" + id, "_blank")
                              }
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                art.filter((x) => x.artId == id)[0].artName[
                                  lang
                                ]
                              }
                              key={id}
                              alt={id}
                              src={art.filter((x) => x.artId == id)[0].artImg}
                            />
                          ))}
                        </AvatarGroup>
                      </div>
                    )}
                    <Divider />
                    <Typography className="mt-2">{list.post.text}</Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      sx={{ fontSize: 18 }}
                      onClick={() => setLike(list.post)}>
                      <StarIcon />
                      &nbsp;
                      {likeload ? (
                        <Skeleton variant="text" width={30} />
                      ) : (
                        list.post.likeCount
                      )}
                    </IconButton>
                  </CardActions>
                </Card>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Card
                sx={{ position: "fixed", zIndex: 10, bottom: 80 }}
                className="col-11">
                <CardContent className="row">
                  <TextField
                    className="col-md-11 col-12"
                    label={
                      lang == "th"
                        ? "แสดงความคิดเห็นที่นี่"
                        : "Add your comment"
                    }
                    disabled={addcomment}
                    required
                    variant="filled"
                    maxRows={2}
                    value={inp}
                    onChange={(e) => setInp(e.target.value)}
                    multiline
                    inputProps={{ maxLength: 3000 }}
                    fullWidth={true}
                  />
                  <Button
                    variant="contained"
                    className="ml-3 mb-4 col-md"
                    disabled={addcomment}
                    onClick={() => postcomment(list.post.postId, inp)}>
                    Comment
                  </Button>
                </CardContent>
              </Card>
            </div>
          </>
          <Divider
            className={dark ? "border-light mt-3" : "border-dark mt-3"}
          />
          {list != null &&
            list.comments.map((item) => (
              <Card className="m-3 text-left">
                <CardHeader
                  action={
                    <>
                      {login != null &&
                        item.author.split("|")[1] != login.fromsystem.memId && (
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => setReportHook2(item)}>
                            <ReportProblemIcon />
                          </IconButton>
                        )}
                      {item.author.split("|")[1] == login.fromsystem.memId && (
                        <IconButton
                          size="large"
                          onClick={() => deleteCom(item)}
                          color="inherit">
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </>
                  }
                  title={
                    item.author.split("|")[1] == login.fromsystem.memId
                      ? item.author.split("|")[0] + " (You)"
                      : item.author.split("|")[0]
                  }
                  subheader={
                    (lang == "th" ? "แสดงความคิดเห็นเมื่อ " : "Comment in ") +
                    moment(item.createdAt)
                      .lang(lang)
                      .local()
                      .format("DD MMMM YYYY HH:mm:ss")
                  }
                />
                <CardContent className="col-12">
                  <Divider />
                  <Typography className="mt-2">{item.text}</Typography>
                </CardContent>
                <CardActions>
                  <IconButton
                    sx={{ fontSize: 18 }}
                    onClick={() => setLikeComment(list.post, item.commentId)}>
                    <StarIcon />
                    &nbsp;
                    {likecomload ? (
                      <Skeleton variant="text" width={30} />
                    ) : (
                      item.likeCount
                    )}
                  </IconButton>
                </CardActions>
              </Card>
            ))}

          {width >= 800 && !uploadState && (
            <Fab
              sx={{ position: "fixed", bottom: 240, right: 10 }}
              onClick={() => {
                FetchList(login != null && !ro ? login.fromsystem.memId : null);
              }}
              className="bg-info text-light">
              <RefreshIcon />
            </Fab>
          )}
          <Fab
            sx={{ position: "fixed", bottom: 170, right: 10 }}
            onClick={() => {
              his.push("/community?idsec=" + encodeURI(list.post.postId));
            }}
            className="bg-warning">
            <ArrowBackIosNewIcon />
          </Fab>
        </>
      )}
      <Dialog
        component="form"
        onSubmit={(e) => uploadReport(e)}
        open={reporthook != null}
        maxWidth="xl">
        {reporthook != null && (
          <>
            <DialogTitle component="h4">
              {lang == "th"
                ? 'รายงานกระทู้ "' + reporthook.head + '"'
                : 'Report article "' + reporthook.head + '"'}
            </DialogTitle>
            <DialogContent>
              <Typography className="mt-3">
                {lang == "th"
                  ? "กรุณาระบุรายละเอียดที่ต้องการรายงาน โดยคุณสามารถรายงานโพสต์นี้ได้ 1 ครั้งเท่านั้น และสามารถรายงานได้สูงสุด 10 โพสต์ต่อวัน"
                  : "Please select report information below. You can report this article only one time. And report articles up to 10 posts per day."}
              </Typography>
              <TextField
                className="mt-4"
                select
                label={
                  lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"
                }
                onChange={(e) =>
                  e.target.value != "-"
                    ? setReportStructure({ ...report, topic: e.target.value })
                    : null
                }
                helperText={
                  lang == "th"
                    ? "แนะนำให้กรอกข้อมูลเพิ่มเติมในการรายงานเพิ่มเติมเพื่อให้ทีมงานตรวจสอบได้รวดเร็วขึ้น"
                    : "We recommend that you should add more report detail below to help us analysic this article wrong faster."
                }
                defaultValue={"-"}
                fullWidth={true}
                required>
                <MenuItem value={"-"}>
                  {lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"}
                </MenuItem>
                {reportList.map((option) => (
                  <MenuItem key={option.val} value={option.val}>
                    {option[lang]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                multiline
                label={
                  lang == "th"
                    ? "รายละเอียดเพิ่มเติม"
                    : "Explain more description."
                }
                onChange={(e) =>
                  setReportStructure({ ...report, more: e.target.value })
                }
                rows={2}
                variant="standard"
                className="mt-2"
                fullWidth={true}
              />
              <ReCAPTCHA
                className="mt-4"
                sitekey="6LfWaT0kAAAAAJ85uQ2mGp69o8VOSOSyy4bMUhze"
                onError={() => setKey("")}
                onExpired={() => {
                  Swal.fire({
                    title: "Verification expired",
                    icon: "error",
                    text:
                      lang == "th"
                        ? "เซสชั่นการยืนยันตัวตนหมดอายุ กรุณาลองใหม่อีกครั้ง"
                        : "Bot Verify session is expired. Please try again",
                  });
                  setKey("");
                }}
                hl={lang}
                ref={recaptchaRef}
                onChange={(e) => setKey(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="outlined" onClick={() => {}}>
                {lang == "th" ? "รายงาน" : "Report"}
              </Button>
              <Button
                onClick={() => {
                  setKey("");
                  setReportHook(null);
                  setReportStructure({
                    topic: "-",
                    more: "",
                  });
                }}>
                {lang == "th" ? "ปิด" : "Close"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        component="form"
        onSubmit={(e) => uploadReport2(e)}
        open={reporthook2 != null}
        maxWidth="xl">
        {reporthook2 != null && (
          <>
            <DialogTitle component="h4">
              {lang == "th"
                ? 'รายงานความเห็น "' + reporthook2.text + '"'
                : 'Report comment "' + reporthook2.text + '"'}
            </DialogTitle>
            <DialogContent>
              <Typography className="mt-3">
                {lang == "th"
                  ? "กรุณาระบุรายละเอียดที่ต้องการรายงาน โดยคุณสามารถรายงานโพสต์นี้ได้ 1 ครั้งเท่านั้น และสามารถรายงานได้สูงสุด 10 โพสต์ต่อวัน"
                  : "Please select report information below. You can report this article only one time. And report articles up to 10 posts per day."}
              </Typography>
              <TextField
                className="mt-4"
                select
                label={
                  lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"
                }
                onChange={(e) =>
                  e.target.value != "-"
                    ? setReportStructure2({ ...report2, topic: e.target.value })
                    : null
                }
                helperText={
                  lang == "th"
                    ? "แนะนำให้กรอกข้อมูลเพิ่มเติมในการรายงานเพิ่มเติมเพื่อให้ทีมงานตรวจสอบได้รวดเร็วขึ้น"
                    : "We recommend that you should add more report detail below to help us analysic this comment wrong faster."
                }
                defaultValue={"-"}
                fullWidth={true}
                required>
                <MenuItem value={"-"}>
                  {lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"}
                </MenuItem>
                {reportList.map((option) => (
                  <MenuItem key={option.val} value={option.val}>
                    {option[lang]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                multiline
                label={
                  lang == "th"
                    ? "รายละเอียดเพิ่มเติม"
                    : "Explain more description."
                }
                onChange={(e) =>
                  setReportStructure2({ ...report2, more: e.target.value })
                }
                rows={2}
                variant="standard"
                className="mt-2"
                fullWidth={true}
              />
              <ReCAPTCHA
                className="mt-4"
                sitekey="6LfWaT0kAAAAAJ85uQ2mGp69o8VOSOSyy4bMUhze"
                onError={() => setKey("")}
                onExpired={() => {
                  Swal.fire({
                    title: "Verification expired",
                    icon: "error",
                    text:
                      lang == "th"
                        ? "เซสชั่นการยืนยันตัวตนหมดอายุ กรุณาลองใหม่อีกครั้ง"
                        : "Bot Verify session is expired. Please try again",
                  });
                  setKey("");
                }}
                hl={lang}
                ref={recaptchaRef}
                onChange={(e) => setKey(e)}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="outlined" onClick={() => {}}>
                {lang == "th" ? "รายงาน" : "Report"}
              </Button>
              <Button
                onClick={() => {
                  setKey("");
                  setReportHook2(null);
                  setReportStructure2({
                    topic: "-",
                    more: "",
                  });
                }}>
                {lang == "th" ? "ปิด" : "Close"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage,
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ViewPost);
