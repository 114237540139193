import React from 'react'
import pagedetail from '../menulist/Top.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, Avatar, CardContent, ListItem, ListItemButton, ListItemText, ListItemAvatar, CardActionArea, Grid } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";
import moment from 'moment'

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const gold1 = '-webkit-linear-gradient(top, #8f6B29, #FDE08D, #DF9F28)'
// const gold2 = 'linear-gradient(top, #8f6B29, #FDE08D, #DF9F28)'
const silver = 'linear-gradient(-72deg,#dedede,#ffffff 16%,#dedede 21%,#ffffff 24%,#454545 27%,#dedede 36%,#ffffff 45%,#ffffff 60%,#dedede 72%,#ffffff 80%,#dedede 84%,#a1a1a1);'
const bronze = 'linear-gradient(-72deg,#ca7345,#ffdeca 16%,#ca7345 21%,#ffdeca 24%,#a14521 27%,#ca7345 36%,#ffdeca 45%,#ffdeca 60%,#ca7345 72%,#ffdeca 80%,#ca7345 84%,#732100);'

const Top = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [fwoptions, setfwoptions] = React.useState({
      speed: 2,
      delay: { min: 10, max: 1500 },
    });
    const [systemupdate, setUpdate] = React.useState('Loading');
    const [rootArr, setRootArr] = React.useState([]);
    // const [langselect, setLang] = React.useState('en');
    const [instantview, setInstantview] = React.useState([]);
    const History = useHistory();
  
    const removefonttag = (data) => {
        var div = document.createElement('div');

        // assing your HTML to div's innerHTML
        div.innerHTML = data;

        // get all <a> elements from div
        var elements = div.getElementsByTagName('font');

        // remove all <a> elements
        while (elements[0])
        elements[0].parentNode.removeChild(elements[0])

        // get div's innerHTML into a new variable
        var repl = div.innerHTML;
        return repl
    }
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (width < 800) {
        setfwoptions({
          speed: 1,
          delay: { min: 10, max: 4000 },
        })
      }
  
      if (lang == 'th') {
        setPage('อันดับเพลง')
      } else {
        setPage('Top Chart')
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.runasp.net/tpop/tophits', {
        method: 'post'
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.tracks.items.length > 0) {
            setUpdate(data.tracks.items[0].added_at)
            setRootArr(data.tracks.items)

            setTimeout(() => {
              fetch('https://cpxdevweb.runasp.net/tpop/instantlistartists', {
                  method: 'post'
              })
                  .then((response) => response.json())
                  .then((data) => {
                  if (data.length > 0) {
                      setInstantview(data)
                  }
                  });
            }, 1000);
          }
          setLoad(false)
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    React.useEffect(() => {
      setLang(lang)
    }, [lang]);

    React.useEffect(() => {
      const current = document.documentElement.scrollTop
      window.scrollTo(0, document.documentElement.scrollHeight);
      window.scrollTo(0, 0);
      window.scrollTo(0, current);
    }, [dark])

    const getLink = (spIdfrommain) => {
      let link = ""
      if (instantview.length > 0 && instantview.filter(x => x.spotID == spIdfrommain).length > 0) {
          link = instantview.filter(x => x.spotID == spIdfrommain)[0].artId
      }
      return link
  }

  const changep = (artid) => {
      setLoad(true)
      setTimeout(() => History.push('/artist/' + artid), 700)
    }

    if (load) return null
    return ( 
        <>
          <CardHeader title={(<h3 className={dark ? 'text-light' : ''}>{pagedetail[lang].title}</h3>)} subheader={(<p className={dark ? 'text-light' : ''}>{pagedetail[lang].update}</p>)} />
          <div className='text-center'>
          <Grid container spacing={2}>
              {rootArr.map((item, i) => i < 3 && (
                <Grid item md={4}>
                  <Card key={item.track.id} data-aos="zoom-in-up" className={'mt-2 text-center'} sx={{backgroundColor: (dark ? '#02012b' : '')}}>
                    <CardContent className='rounded-im' sx={i == 0 ? {background: gold1} : i == 1 ? {background: silver} : i == 2 ? {background: bronze} : null}>
                      <CardActionArea>
                        <CardMedia className='mb-2 imgcircle' src={item.track.album.images[0].url} component='img' onClick={() => window.open(item.track.external_urls.spotify, "_blank")} />
                          <CardHeader title={"#" +(i +1) + " " + item.track.name} subheader={(<p>
                          {item.track.artists.map((art, i) => (<a onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: dark ? '#61dafb' : '#0a05f2'} : {}}>{art.name + (i == item.track.artists.length - 1 ? '' : ', ')}</a>))}
                          </p>
                      )} />
                      </CardActionArea>
                    </CardContent>
                  </Card>
                  </Grid>
                ))}
              {rootArr.map((item ,i) => i > 2 && (
                 <ListItem data-aos="fade-right" disablePadding key={item.track.id} className={"mt-2" + (width > 700 ? " pl-5  pr-5" : "")}>
                    <ListItemButton>
                      <ListItemAvatar onClick={() => window.open(item.track.external_urls.spotify, "_blank")}>
                        <Avatar variant='rounded' alt={item.track.name} src={item.track.album.images[0].url} sx={width > 800 ? { width: 100, height: 100 } : {width: 70, height: 70}} />
                      </ListItemAvatar>
                      <ListItemText className='ml-3' primary={
                        (<Typography variant='h6' className={dark ? 'text-light' : ''}>{item.track.name}</Typography>)} 
                        secondary={(<p>
                          {item.track.artists.map((art, i) => (<a onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: dark ? '#61dafb' : '#0a05f2'} : {color: dark ? '#fff' : 'rgb(46, 46, 46)'}}>{art.name + (i == item.track.artists.length - 1 ? '' : ', ')}</a>))}
                          </p>
                      )} 
                    />
                    </ListItemButton>
                </ListItem>
                ))}
              </Grid>
          </div>
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Top);