import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import { setLoad, setLang, setDarkMode, setPage } from "../redux/action";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import {
  Snackbar,
  Alert,
  Typography,
  Skeleton,
  Card,
  CardActionArea,
  LinearProgress,
  Backdrop,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  TextField,
  MenuItem,
  AvatarGroup,
  Fab,
  Divider,
  Button,
  Drawer,
  Box,
  FormControlLabel,
  Chip,
  Checkbox,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import StarIcon from "@mui/icons-material/Star";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommentIcon from "@mui/icons-material/Comment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ReCAPTCHA from "react-google-recaptcha";
import { Carousel as MobileCarousel } from "react-responsive-carousel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import reportList from "../menulist/reportList.json";

const loadske = [0, 0, 0, 0, 0];

const CommunityList = ({
  dark,
  load,
  lang,
  currentPage,
  setLoad,
  setDark,
  setLang,
  setPage,
  login,
}) => {
  const recaptchaRef = React.createRef();
  const History = useHistory();
  const [ro, setReadOnly] = React.useState(false);
  const [all, setAll] = React.useState(null);
  const [list, setList] = React.useState(null);
  const [art, setArt] = React.useState([]);
  const searchBox = React.useRef(null);
  const [sbSet, setSB] = React.useState(0);
  const [likeload, setLikeLoad] = React.useState(false);
  const [addPostLoad, setAddPostLoad] = React.useState(false);
  const [width, setRealwidth] = React.useState(window.innerWidth);
  const [img, setImg] = React.useState([]);
  const [reporthook, setReportHook] = React.useState(null);
  const [report, setReportStructure] = React.useState({
    topic: "-",
    more: "",
  });

  const [filter, setFilter] = React.useState("-");
  const [key, setKey] = React.useState("");
  const [add, setAdd] = React.useState(false);
  const [uploadState, setUp] = React.useState(false);

  const [inp, setInput] = React.useState({
    head: "",
    text: null,
    tag: [],
    imgUploadId: "",
  });

  const FetchList = async (mem) => {
    if (uploadState) {
      return;
    }
    setList(null);
    setAdd(false);
    fetch("https://tpopcom.azurewebsites.net/api/list/listPost", {
      method: "get",
      headers:
        mem != null
          ? {
              Authorization: mem,
              "Content-Type": "application/json",
            }
          : {
              "Content-Type": "application/json",
            },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setAll(data.responses);
          if (filter != "-") {
            setList(
              data.responses.filter((x) => x.artistTag.indexOf(filter) > -1)
            );
            return;
          } else {
            setList(data.responses);
            var url = new URL(window.location.href);
            var c = url.searchParams.get("idsec");
            setTimeout(() => {
              if (c != null) {
                window.scrollTo({
                  behavior: "smooth",
                  top:
                    document.getElementById(c).getBoundingClientRect().top + 0,
                });
                window.history.replaceState(null, "", window.location.pathname);
              }
            }, 600);
            return;
          }
        }
      });
  };

  React.useEffect(() => {
    if (list != null) {
      if (filter != "-") {
        setList(all.filter((x) => x.artistTag.indexOf(filter) > -1));
        return;
      } else {
        setList(all);
        return;
      }
    }
  }, [filter]);

  React.useEffect(() => {
    if (searchBox.current != null) {
      setSB(searchBox.current.clientHeight);
    }
  }, [searchBox.current, list, width]);

  React.useEffect(() => {
    if (lang == "th") {
      setPage("คอมมูนิตี้");
    } else {
      setPage("Community");
    }
    function handleWindowResize() {
      setRealwidth(window.innerWidth);
    }

    //AOS.init({ duration: 800 });
    window.addEventListener("resize", handleWindowResize);
    fetch("https://cpxdevweb.runasp.net/tpop/artistlist?lang=" + lang, {
      method: "post",
    })
      .then((response) => response.json())
      .then((data) => {
        setArt(data);
      });
    if (login != null) {
      setLoad(false);
      setReadOnly(false);
      FetchList(login.fromsystem.memId);
    } else {
      setLoad(false);
      setReadOnly(true);
      FetchList(null);
    }
  }, []);

  const addTag = (o) => {
    if (inp.tag.indexOf(o) > -1) {
      Swal.fire({
        title: "This artist is already added",
        text:
          lang == "th"
            ? "คุณเพิ่มศิลปินท่านนี้แล้ว"
            : "You already add this artist.",
        icon: "warning",
      });
      return;
    }
    if (inp.tag.length == 3) {
      Swal.fire({
        title: "Artist Tags maximum reached",
        text:
          lang == "th"
            ? "คุณเพิ่มแท็กศิลปินได้สูงสุด 3 คน"
            : "You can add artist atgs up to 3 artists",
        icon: "warning",
      });
      return;
    }
    let datatemp = inp.tag;
    datatemp.push(o);
    console.log(datatemp);
    setInput({ ...inp, tag: datatemp });
  };

  const imgUpload = () => {
    if (login.fromsystem.verified == false) {
      Swal.fire({
        title: "You cannot change profile image",
        text:
          lang == "th"
            ? "กรุณายืนยันบัญชีผู้ใช้ก่อนเพิ่มหรือเปลี่ยนโปรไฟล์รูปภาพ"
            : "Please confirm account before upload profile image",
        icon: "warning",
      });
      return;
    }
    if (img.length == 3) {
      Swal.fire({
        title: "Image upload reached",
        text:
          lang == "th"
            ? "คุณสามารถอัปโหลดรูปได้สูงสุด 3 รูปต่อโพสต์"
            : "You can upload up to 3 images per post.",
        icon: "warning",
      });
      return;
    }
    var input = document.createElement("input");
    input.type = "file";
    input.click();
    input.addEventListener(
      "change",
      function () {
        if (!input.files) {
          // This is VERY unlikely, browser support is near-universal
          console.error(
            "This browser doesn't seem to support the `files` property of file inputs."
          );
        } else {
          var file = input.files[0];
          if (!file.type.includes("jpeg") && !file.type.includes("png")) {
            Swal.fire({
              title: "File type is unsupport",
              text:
                lang == "th"
                  ? "คุณสามารถอัปโหลดเป็นไฟล์รูปภาพได้เท่านั้น"
                  : "Please choose only image file.",
              icon: "warning",
            });
            input.value = null;
          } else if (file.size > 3670016) {
            Swal.fire({
              title: "File size exceeded.",
              text:
                lang == "th"
                  ? "ไฟล์รูปภาพมีขนาดใหญ่เกินไป"
                  : "File is too large.",
              icon: "warning",
            });
            input.value = null;
          } else {
            if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.onload = function (e) {
                if (img.findIndex((x) => x == e.target.result) > -1) {
                  Swal.fire({
                    title: "Image already added",
                    text:
                      lang == "th"
                        ? "คุณไม่สามารถอัปโหลดรูปซ้ำได้"
                        : "You cannot upload duplicated image.",
                    icon: "warning",
                  });
                  return;
                }
                setImg((current) => [...current, e.target.result]);
              };
              reader.readAsDataURL(input.files[0]); // convert to base64 string
            }
          }
        }
      },
      false
    );
  };

  const ImgView = (imgsec) => {
    Swal.fire({
      imageUrl: imgsec,
      imageWidth: "100%",
      imageAlt: "A tall image",
      showDenyButton: true,
      confirmButtonText: lang == "th" ? "ปิด" : "Close",
      denyButtonText: lang == "th" ? "ลบรูปนี้" : "Delete this image",
    }).then((result) => {
      if (result.isDenied) {
        Swal.fire({
          title: "Delete this image from list",
          text:
            lang == "th"
              ? "คุณต้องการลบรูปนี้หรือไม่"
              : "Are you sure to delete this image.",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: lang == "th" ? "ยืนยัน" : "Confirm",
          cancelButtonText: lang == "th" ? "ปิด" : "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            let datatemp = img;
            datatemp = datatemp.filter((x) => x !== imgsec);
            setImg(datatemp);
          }
        });
      }
    });
  };

  const getSub = (e) => {
    e.preventDefault();
    if (key == "") {
      Swal.fire({
        title: "Bot verification fail",
        text:
          lang == "th"
            ? "กรุณายืนยัน Bot verification ก่อนอัปโหลดโพสต์"
            : "Please verify Bot verification.",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      title: "Final Confirmation",
      text:
        lang == "th"
          ? "คุณต้องการยืนยันการอัปโหลดหรือไม่"
          : "Do you want to upload this post?",
      icon: "question",
      footer:
        lang == "th"
          ? "หลังจากอัปโหลดโพสต์นี้แล้วจะไม่สามารถดำเนินการแก้ไขได้"
          : "You cannot edit this post after uploaded.",
      showCancelButton: true,
      confirmButtonText: lang == "th" ? "ยืนยัน" : "Confirm",
      cancelButtonText: lang == "th" ? "ปิด" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        setAddPostLoad(true);
        fetch(
          "https://tpopcom.azurewebsites.net/api/uploadpost/uploadImageSession",
          {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              authorization: login.fromsystem.memId,
            },
            body: JSON.stringify(img),
          }
        )
          .then((response) => response.json())
          .then((emc) => {
            setAddPostLoad(false);
            if (emc.status == true) {
              setInput({ ...inp, imgUploadId: emc.uploadId });
              setAdd(false);
              setUp(true);
              let uploadform = inp;
              uploadform.imgUploadId = emc.uploadId;
              fetch(
                "https://tpopcom.azurewebsites.net/api/uploadpost/createPost",
                {
                  method: "post",
                  headers: {
                    "Content-Type": "application/json",
                    authorization: login.fromsystem.memId,
                  },
                  body: JSON.stringify(uploadform),
                }
              )
                .then((response) => response.json())
                .then((data) => {
                  if (data.status == true) {
                    setTimeout(() => {
                      setUp(false);
                      FetchList(login.fromsystem.memId);
                    }, 3000);
                  } else {
                    setUp(false);
                    Swal.fire({
                      title: "Something went wrong",
                      text: data.message,
                      icon: "error",
                    });
                  }
                });
            } else {
              setUp(false);
              Swal.fire({
                title: "Something went wrong",
                text: lang == "th" ? "พบข้อผิดพลาด" : "Please try again.",
                icon: "error",
              });
            }
          });
      }
    });
  };

  const uploadReport = (e) => {
    e.preventDefault();
    if (key == "") {
      Swal.fire({
        title: "Bot verification fail",
        text:
          lang == "th"
            ? "กรุณายืนยัน Bot verification ก่อนอัปโหลดรายงาน"
            : "Please verify Bot verification.",
        icon: "warning",
      });
      return;
    }
    if (report.topic == "-") {
      Swal.fire({
        title: "Report topic not selected",
        text:
          lang == "th"
            ? "กรุณาเลือกเหตุผลในการรายงาน"
            : "Please choose report reason.",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      title: "Final Confirmation",
      text:
        lang == "th"
          ? "คุณต้องการยืนยันการอัปโหลดหรือไม่"
          : "Do you want to upload this report?",
      icon: "question",
      footer:
        lang == "th"
          ? "หลังจากอัปโหลดรายงานนี้แล้วจะไม่สามารถดำเนินการแก้ไขได้"
          : "You cannot edit this report after uploaded.",
      showCancelButton: true,
      confirmButtonText: lang == "th" ? "ยืนยัน" : "Confirm",
      cancelButtonText: lang == "th" ? "ปิด" : "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        const reporth = reporthook;
        setUp(true);
        setReportHook(null)
        fetch(
          "https://tpopcom.azurewebsites.net/api/report/createReport",
          {
            method: "put",
            headers: {
              "Content-Type": "application/json",
              authorization: login.fromsystem.memId,
            },
            body: JSON.stringify({
              reportFrom: login.fromsystem.memId,
              reportTo: reporth.author.split("|")[1],
              reportPost: reporth.postId,
              reportHead: report.topic,
              reportDesc: report.more
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setUp(false);
            setReportStructure({
              topic: "-",
              more: "",
            })
            if (data.status == true) {
              setUp(false);
              Swal.fire({
                title: "Report successfully",
                text: lang == "th" ? "อัปโหลดรายงานแล้ว" : "Uploaded report success",
                icon: "success",
              });
            } else {
              setUp(false);
              Swal.fire({
                title: "Something went wrong",
                text: data.message,
                icon: "error",
              });
            }
          });
      }
    });
  };

  return (
    <div>
      <Snackbar
        open={uploadState}
        className="justify-content-center"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="info" variant="filled">
          <Typography variant="h6" className="mb-1">
            {lang == "th" ? "กำลังอัปโหลด กรุณารอสักครู่" : "Uploading, please wait"}
          </Typography>
          <LinearProgress />
        </Alert>
      </Snackbar>

      {list == null ? (
        <>
          <Card className="mt-3">
            <CardHeader
              title={<Skeleton variant="text" width="100%" />}
              subheader={<Skeleton variant="text" width="30%" />}
            />
            <CardContent>
              <Skeleton
                className="mb-2"
                variant="rounded"
                width="100%"
                height={300}
              />
              {loadske.map((item) => (
                <Skeleton variant="text" width="100%" sx={{ fontSize: 15 }} />
              ))}
            </CardContent>
            <CardActions>
              <IconButton size="small">
                <StarIcon /> <Skeleton variant="text" width={30} />
              </IconButton>
              <IconButton size="small">
                <CommentIcon />
                &nbsp;
                <Skeleton variant="text" width={30} />
              </IconButton>
            </CardActions>
          </Card>
        </>
      ) : (
        <>
          <Drawer open={add} anchor="right">
            <form onSubmit={(e) => getSub(e)}>
              <Box sx={{ width: 400 }}>
                <CardHeader
                  title={lang == "th" ? "โพสต์ข่าวสาร" : "Post new article"}
                />
                <Divider />
                <CardContent>
                  <TextField
                    label={lang == "th" ? "หัวข้อกระทู้" : "Article title"}
                    required
                    variant="filled"
                    value={inp.head}
                    onChange={(e) => setInput({ ...inp, head: e.target.value })}
                    multiline
                    helperText={
                      lang == "th"
                        ? "ระบุได้สูงสุด 250 ตัวอักษร"
                        : "Up to 250 characters"
                    }
                    inputProps={{ maxLength: 250 }}
                    fullWidth={true}
                  />
                  <TextField
                    className="mt-4"
                    select
                    label={lang == "th" ? "เลือกศิลปิน" : "Choose Artist"}
                    onChange={(e) =>
                      e.target.value != "-" ? addTag(e.target.value) : null
                    }
                    helperText={
                      lang == "th"
                        ? "สามารถเพิ่มไดเ้สูงสุด 3 ศิลปิน หากคุณไม่กำหนด ระบบจะแสดงโพสต์นี้เป็นแบบสาธารณะและจะไม่สามารถแสดงผลโดยกรองตามชื่อศิลปินได้"
                        : "Add up to 3 artists. You can leave this field but you maybe not see this post on filer by artists."
                    }
                    defaultValue={"-"}
                    fullWidth={true}>
                    <MenuItem value={"-"}>
                      {lang == "th" ? "เลือกศิลปิน" : "Choose artists"}
                    </MenuItem>
                    {art.map((option) => (
                      <MenuItem key={option.artId} value={option.artId}>
                        {option.artName[lang]}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="row mt-1 p-2">
                    {inp.tag.map((it) => (
                      <Chip
                        color="success"
                        className="m-1"
                        label={
                          art.filter((x) => x.artId == it)[0].artName[lang]
                        }
                        variant="outlined"
                        onDelete={() =>
                          setInput({
                            ...inp,
                            tag: inp.tag.filter((x) => x !== it),
                          })
                        }
                      />
                    ))}
                  </div>
                  <TextField
                    className="mt-3 mb-3"
                    multiline
                    label={
                      lang == "th" ? "ข้อความบนกระทู้" : "Article Description"
                    }
                    required
                    variant="filled"
                    maxRows={4}
                    value={inp.text}
                    onChange={(e) => setInput({ ...inp, text: e.target.value })}
                    inputProps={{ maxLength: 3000 }}
                    fullWidth={true}
                  />
                  <Button
                    variant="outlined"
                    color="info"
                    className="col-12"
                    onClick={() => imgUpload()}>
                    {lang == "th" ? "อัปโหลดรูป" : "Upload Image"}
                  </Button>
                  <List>
                    {img.map((imp) => (
                      <CardActionArea onClick={() => ImgView(imp)}>
                        <ListItem alignItems="flex-start" key={imp}>
                          <ListItemAvatar>
                            <Avatar src={imp} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              lang == "th"
                                ? "คลิกที่นี่เพื่อดูรูป"
                                : "Click here to view image"
                            }
                          />
                        </ListItem>
                      </CardActionArea>
                    ))}
                  </List>

                  <ReCAPTCHA
                    className="mt-4 mb-5"
                    sitekey="6LfWaT0kAAAAAJ85uQ2mGp69o8VOSOSyy4bMUhze"
                    onError={() => setKey("")}
                    onExpired={() => {
                      Swal.fire({
                        title: "Verification expired",
                        icon: "error",
                        text:
                          lang == "th"
                            ? "เซสชั่นการยืนยันตัวตนหมดอายุ กรุณาลองใหม่อีกครั้ง"
                            : "Bot Verify session is expired. Please try again",
                      });
                      setKey("");
                    }}
                    hl={lang}
                    ref={recaptchaRef}
                    onChange={(e) => setKey(e)}
                  />
                </CardContent>
                <DialogActions>
                  <Button type="submit" variant="outlined" onClick={() => {}}>
                    {lang == "th" ? "โพสต์" : "Post"}
                  </Button>
                  <Button
                    onClick={() => {
                      if (
                        JSON.stringify({
                          head: "",
                          text: null,
                          tag: [],
                          imgUploadId: "",
                        }) == JSON.stringify(inp) &&
                        img.length == 0
                      ) {
                        setAdd(false);
                        return;
                      }
                      Swal.fire({
                        title: "Do you want to cancel this form",
                        text:
                          lang == "th"
                            ? "คุณจะไม่สามารถกู้คืนฟอร์มอัปโหลตนี้ได้"
                            : "You cannot recover uploaded this form later.",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonText: lang == "th" ? "ตกลง" : "Okay",
                        cancelButtonText: lang == "th" ? "ปิด" : "Close",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setAdd(false);
                        }
                      });
                    }}>
                    {lang == "th" ? "ยกเลิก" : "Cancel"}
                  </Button>
                </DialogActions>
              </Box>
            </form>
          </Drawer>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: -35 }}>
            <Card
              className="mt-3 text-center"
              sx={{ position: "fixed", width: "95%", zIndex: 10 }}
              ref={searchBox}>
              <CardContent>
                <CardHeader
                  title={lang == "th" ? "ออนไลน์คอมมูนิตี้" : "T-POP Community"}
                  subheader={
                    lang == "th"
                      ? "ศูนย์รวมข่าวสารและกิจกรรมพิเศษของแฟนด้อมของศิลปิน T-POP ที่คุณชื่นชอบ"
                      : "Community for sharing or discussing about your favorite T-POP artist in one place."
                  }
                />
                <CardHeader
                  sx={{ margin: -4 }}
                  subheader={
                    lang == "th"
                      ? "คำแนะนำ: คุณสามารถลากกล่องข้อความนี้ลงและปล่อยเพื่อรีเฟรชรายการโพสต์ได้"
                      : "Guide: You can pull and release this box to refresh posts list"
                  }
                />
                <CardContent className="text-left justify-content-center row mt-4">
                  <div className="col-md-3">
                    <TextField
                      id="outlined-select-currency"
                      select
                      label={
                        lang == "th"
                          ? "กรองตามชื่อศิลปิน"
                          : "Filter by artist name"
                      }
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                      fullWidth={true}>
                      <MenuItem value={"-"}>
                        {lang == "th" ? "ทุกศิลปิน" : "All artists"}
                      </MenuItem>
                      {art.map((option) => (
                        <MenuItem key={option.artId} value={option.artId}>
                          {option.artName[lang]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {/* <div className='col-md-4'>
                        <TextField label="Filled" variant="filled" fullWidth={true} />
                    </div>
                    <div className='col-md-4'>
                        <TextField label="Filled" variant="filled" fullWidth={true} />
                    </div> */}
                </CardContent>
              </CardContent>
            </Card>
          </div>

          <div style={{ marginTop: sbSet + 10 }} />
          <>
            {width < 800 ? (
              <PullToRefresh
                onRefresh={() =>
                  FetchList(
                    login != null && !ro ? login.fromsystem.memId : null
                  )
                }
                className="text-center"
                height="100%"
                displayName="">
                {list.map((item) => (
                  <Card className="mt-3 text-left" id={item.id} key={item.id}>
                    <CardHeader
                      action={
                        <>
                          {login != null &&
                            item.author.split("|")[1] !=
                              login.fromsystem.memId && (
                              <IconButton
                                onClick={() => setReportHook(item)}
                                size="large"
                                color="inherit">
                                <ReportProblemIcon />
                              </IconButton>
                            )}
                        </>
                      }
                      title={item.head}
                      subheader={
                        (login != null &&
                        item.author.split("|")[1] == login.fromsystem.memId
                          ? lang == "th"
                            ? "โพสต์โดยคุณเมื่อ "
                            : "Posted by you in "
                          : lang == "th"
                          ? "โพสต์โดย " + item.author.split("|")[0] + " เมื่อ "
                          : "Posted by " + item.author.split("|")[0] + " in ") +
                        moment(item.createdAt)
                          .lang(lang)
                          .local()
                          .format("DD MMMM YYYY HH:mm:ss")
                      }
                    />
                    <CardContent>
                      <MobileCarousel showArrows showStatus={false}>
                        {item.img.map((img, i) => (
                          <CardMedia src={img} component="img" />
                        ))}
                      </MobileCarousel>
                      {item.artistTag.length > 0 && (
                        <div className="d-flex justify-content-start mb-2">
                          <Typography className="text-bold pt-1 mr-3">
                            {lang == "th"
                              ? "ศิลปินที่เกี่ยวข้อง: "
                              : "Related Artist: "}
                            {item.artistTag.map((id, i) =>
                              i == item.artistTag.length - 1 ? (
                                <span
                                  className="text-info"
                                  onClick={() =>
                                    window.open("/artist/" + id, "_blank")
                                  }>
                                  {
                                    art.filter((x) => x.artId == id)[0].artName[
                                      lang
                                    ]
                                  }
                                </span>
                              ) : (
                                <span
                                  className="text-info"
                                  onClick={() =>
                                    window.open("/artist/" + id, "_blank")
                                  }>
                                  {
                                    art.filter((x) => x.artId == id)[0].artName[
                                      lang
                                    ]
                                  }
                                  ,{" "}
                                </span>
                              )
                            )}
                          </Typography>
                        </div>
                      )}
                      <Divider />
                      <Typography className="mt-2">{item.text}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton sx={{ fontSize: 18 }}>
                        <StarIcon />
                        &nbsp;
                        {likeload ? (
                          <Skeleton variant="text" width={30} />
                        ) : (
                          item.likeCount
                        )}
                      </IconButton>
                      <IconButton
                        sx={{ fontSize: 18 }}
                        onClick={() =>
                          History.push("/communitypost/" + btoa(item.postId))
                        }
                        size="small">
                        <CommentIcon />
                        &nbsp;{item.commentCount}
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}
              </PullToRefresh>
            ) : (
              <div className="" style={{ paddingTop: 15 }}>
                {list.map((item) => (
                  <Card
                    className="mt-3 text-left"
                    id={item.postId}
                    key={item.postId}>
                    <CardHeader
                      action={
                        <>
                          {login != null &&
                            item.author.split("|")[1] !=
                              login.fromsystem.memId && (
                              <IconButton
                                onClick={() => setReportHook(item)}
                                size="large"
                                color="inherit">
                                <ReportProblemIcon />
                              </IconButton>
                            )}
                        </>
                      }
                      title={item.head}
                      subheader={
                        (login != null &&
                        item.author.split("|")[1] == login.fromsystem.memId
                          ? lang == "th"
                            ? "โพสต์โดยคุณเมื่อ "
                            : "Posted by you in "
                          : lang == "th"
                          ? "โพสต์โดย " + item.author.split("|")[0] + " เมื่อ "
                          : "Posted by " + item.author.split("|")[0] + " in ") +
                        moment(item.createdAt)
                          .lang(lang)
                          .local()
                          .format("DD MMMM YYYY HH:mm:ss")
                      }
                    />
                    <CardContent className="col-12">
                      <MobileCarousel showArrows showStatus={false}>
                        {item.img.map((img, i) => (
                          <CardMedia
                            className="w-50"
                            src={img}
                            component="img"
                          />
                        ))}
                      </MobileCarousel>
                      {item.artistTag.length > 0 && (
                        <div className="d-flex justify-content-start mb-2">
                          <Typography className="text-bold pt-1 mr-3">
                            {lang == "th"
                              ? "ศิลปินที่เกี่ยวข้อง"
                              : "Related Artist"}
                          </Typography>
                          <AvatarGroup max={4} style={{ alignItems: "left" }}>
                            {item.artistTag.map((id) => (
                              <Avatar
                                onClick={() =>
                                  window.open("/artist/" + id, "_blank")
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                                title={
                                  art.filter((x) => x.artId == id)[0].artName[
                                    lang
                                  ]
                                }
                                key={id}
                                alt={id}
                                src={art.filter((x) => x.artId == id)[0].artImg}
                              />
                            ))}
                          </AvatarGroup>
                        </div>
                      )}
                      <Divider />
                      <Typography className="mt-2">{item.text}</Typography>
                    </CardContent>
                    <CardActions>
                      <IconButton sx={{ fontSize: 18 }}>
                        <StarIcon />
                        &nbsp;
                        {likeload ? (
                          <Skeleton variant="text" width={30} />
                        ) : (
                          item.likeCount
                        )}
                      </IconButton>
                      <IconButton
                        sx={{ fontSize: 18 }}
                        onClick={() =>
                          History.push("/communitypost/" + btoa(item.postId))
                        }
                        size="small">
                        <CommentIcon />
                        &nbsp;{item.commentCount}
                      </IconButton>
                    </CardActions>
                  </Card>
                ))}
              </div>
            )}
          </>

          {login != null && !uploadState && (
            <Fab
              sx={{ position: "fixed", bottom: 170, right: 10 }}
              onClick={() => {
                setInput({
                  head: "",
                  text: null,
                  tag: [],
                  imgUploadId: "",
                });
                setImg([]);
                setKey("");
                setAdd(true);
              }}
              className="bg-success text-light">
              <PostAddIcon />
            </Fab>
          )}
          {width >= 800 && !uploadState && (
            <Fab
              sx={{ position: "fixed", bottom: 100, right: 10 }}
              onClick={() => {
                FetchList(login != null && !ro ? login.fromsystem.memId : null);
              }}
              className="bg-info text-light">
              <RefreshIcon />
            </Fab>
          )}

          <Backdrop
            sx={{ zIndex: 2000, position: "fixed" }}
            open={addPostLoad}
            className="text-light">
            <img
              src="https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg"
              width="60px"
            />
            {lang == "th"
              ? "กำลังอัปโหลดรูปภาพ"
              : "Uploading image, please wait"}
          </Backdrop>
        </>
      )}
      <Dialog component='form' onSubmit={(e) => uploadReport(e)} open={reporthook != null} maxWidth="xl">
        {reporthook != null && (
          <>
            <DialogTitle component="h4">
              {lang == "th"
                ? 'รายงานกระทู้ "' + reporthook.head + '"'
                : 'Report article "' + reporthook.head + '"'}
            </DialogTitle>
            <DialogContent>
              <Typography className="mt-3">
                {lang == "th"
                  ? "กรุณาระบุรายละเอียดที่ต้องการรายงาน โดยคุณสามารถรายงานโพสต์นี้ได้ 1 ครั้งเท่านั้น และสามารถรายงานได้สูงสุด 10 โพสต์ต่อวัน"
                  : "Please select report information below. You can report this article only one time. And report articles up to 10 posts per day."}
              </Typography>
              <TextField
                className="mt-4"
                select
                label={
                  lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"
                }
                onChange={(e) =>
                  e.target.value != "-"
                    ? setReportStructure({ ...report, topic: e.target.value })
                    : null
                }
                helperText={
                  lang == "th"
                    ? "แนะนำให้กรอกข้อมูลเพิ่มเติมในการรายงานเพิ่มเติมเพื่อให้ทีมงานตรวจสอบได้รวดเร็วขึ้น"
                    : "We recommend that you should add more report detail below to help us analysic this article wrong faster."
                }
                defaultValue={"-"}
                fullWidth={true} required>
                <MenuItem value={"-"}>
                  {lang == "th"
                    ? "เลือกเหตุผลในการรายงาน"
                    : "Choose report reason"}
                </MenuItem>
                {reportList.map((option) => (
                  <MenuItem key={option.val} value={option.val}>
                    {option[lang]}
                  </MenuItem>
                ))}
              </TextField>
              <TextField multiline label={
                  lang == "th"
                    ? "รายละเอียดเพิ่มเติม"
                    : "Explain more description."
                }  onChange={(e) => setReportStructure({ ...report, more: e.target.value })
                } rows={2} variant="standard" className="mt-2" fullWidth={true} />
                 <ReCAPTCHA
                    className="mt-4"
                    sitekey="6LfWaT0kAAAAAJ85uQ2mGp69o8VOSOSyy4bMUhze"
                    onError={() => setKey("")}
                    onExpired={() => {
                      Swal.fire({
                        title: "Verification expired",
                        icon: "error",
                        text:
                          lang == "th"
                            ? "เซสชั่นการยืนยันตัวตนหมดอายุ กรุณาลองใหม่อีกครั้ง"
                            : "Bot Verify session is expired. Please try again",
                      });
                      setKey("");
                    }}
                    hl={lang}
                    ref={recaptchaRef}
                    onChange={(e) => setKey(e)}
                  />
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="outlined" onClick={() => {}}>
                {lang == "th" ? "รายงาน" : "Report"}
              </Button>
              <Button onClick={() => {
                setKey('')
                setReportHook(null)
                setReportStructure({
                  topic: "-",
                  more: "",
                })
              }}>
                {lang == "th" ? "ปิด" : "Close"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage,
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CommunityList);
