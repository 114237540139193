import React from 'react'
import pagedetail from '../menulist/ArtistsDetail.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, CardActionArea, CardContent, Fab, Skeleton, Dialog, Slide, IconButton, DialogContentText, DialogActions, Button, Grid, Avatar } from '@mui/material';
import moment from 'moment'
import 'moment/locale/th'  // without this line it didn't work
import LiveComponent from './livecomponent/viewlive'

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ShareIcon from '@mui/icons-material/Share';

import {
    useParams,
    useHistory
  } from "react-router-dom";

  import { ColorExtractor } from 'react-color-extractor'

  import { setCORS } from "google-translate-api-browser";

  import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

  const translate = setCORS("https://corsproxy.io/?");
  let audioElement = null
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function numberWithCommas(x) {
    return Number(x).toLocaleString('en');
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
let pushcache = []


const ArtDetail = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage,
  footerref}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [rootArr, setRootArr] = React.useState(null);
    const [follow, setFollow] = React.useState(null);
    const [spot, setSpot] = React.useState(null);

    const [Update, setUpdate] = React.useState(null);
    const [LiveVideo, setVideo] = React.useState(null);
    const [Currentshow, setCurrentshow] = React.useState(null);

    const [fluentColor, setColor] = React.useState('');

    const [followhead, setFollowhead] = React.useState('igfollow');

    const [TextUpdate, setTextUpdate] = React.useState('');

    const [hover, setHover] = React.useState(true);

    const [obj, setObj] = React.useState(null);
    const [dialog, setDialog] = React.useState(false);

    const [scrollPosition, setScrollPosition] = React.useState(0);

    const [overFloat, setFloat] = React.useState(false);

    const imgRef = React.useRef(null);

    let { id } = useParams();
    const History = useHistory();

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        if (position > 800) {
          setFloat(true)
        } else {
          setFloat(false)
        }
        if (width <= 1000) {
          if (position <= 100) {
            setHover(true)
          } else {
            setHover(false)
          }
        }
    };

    const onClose = () => {
      setDialog(false)
      setObj(null)
  }
  const onOpen = (item) => {
      setObj(item)
      setDialog(true)
  }

    React.useEffect(() => {
      setScrollPosition(window.pageYOffset)
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const Live = (id) => {
      fetch('https://cpxdevweb.runasp.net/tpop/getLiveOnArtist?ch=' + id, {
        method: 'post'
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
              setVideo(data)
            } else {
              setVideo([])
            }
        });
    }

    const getAdditional = (sid, tid) => { // Twitter and Spotify
      fetch('https://cpxdevweb.runasp.net/tpop/getDataonArtist', {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          spotify_artId: sid,
          twitter_userId: tid
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error == undefined) {
              setSpot(data.spotify.tracks)
              setUpdate(data.tweet)
              if (data.tweet != null) {
                const ran = Math.floor(Math.random() * data.tweet.length);
                setCurrentshow(ran)
                setInterval(function () {
                    const ran = Math.floor(Math.random() * data.tweet.length);
                    setCurrentshow(ran)
                }, 10000);
              }
            }
        });
  }

   const AutomateTranslator = async (val) => {
   if (lang == 'en') {
    
          
    fetch('https://cpxdevweb.runasp.net/tpop/translateContent', {
      method: 'post',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: val.pubDate,
        word: val.title.replace(/\r?\n|\r/g, " ")
      })
    })
            .then(response => response.json())
            .then(result => {
              if (result.status == true) {
                setTextUpdate(result.translated)
                pushcache.push({
                  id: val.pubDate,
                  text: result.translated
                })
              } else {
                setTextUpdate(val.title)
              }
             
            })
            .catch(error => console.log('error', error));
             
      
    } else {
      setTextUpdate(val.title)
    }
  }

  function getxurl(url, id) {
    if (url.includes('nitter.esmailelbob.xyz')) {
      return url.replace("http://nitter.esmailelbob.xyz/" + id + "/status/", "").replace("#m", "")
    }
    if (url.includes('nitter.mint.lgbt')) {
        return url.replace("https://nitter.mint.lgbt/" + id + "/status/", "").replace("#m", "")
    }
    if (url.includes('nitter.tux.pizza')) {
        return url.replace("https://nitter.tux.pizza/" + id + "/status/", "").replace("#m", "")
    }
    if (url.includes('nitter.no-logs.com')) {
        return url.replace("http://nitter.no-logs.com/" + id + "/status/", "").replace("#m", "")
    }
    return url;
}

  React.useEffect(() => {
    if (Update != null && Currentshow != null && rootArr != null) {
      if (pushcache.filter(x => x.id == getxurl(Update[Currentshow].link, rootArr.tweetID)).length > 0) {
        setTextUpdate(pushcache[pushcache.findIndex(x => x.id == getxurl(Update[Currentshow].link, rootArr.tweetID))].title)
      } else {
        AutomateTranslator(Update[Currentshow])
      }
    }
  }, [Update, Currentshow])

    const FetchFollower = (tid ,keyword) => {
        fetch('https://cpxdevweb.runasp.net/tpop/getfollower?name=' + keyword + '&twitterid=', {
          method: 'post'
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error == undefined) {
                setFollowhead(data.sessionfrom == 'Twitter' ? 'tweetfollow' : 'igfollow')
                setFollow(data.count)
              } else {
                setFollow(0)
              }
          });
    }

    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (rootArr != null) {
        setPage(rootArr.artName[lang])
      } else {
        if (lang == 'th') {
          setPage('ข้อมูลศิลปิน')
        } else {
          setPage('Artist Detail')
        }
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.runasp.net/tpop/artistdetail/' + id, {
          method: 'post'
        })
          .then((response) => response.text())
          .then((data) => {
            if (!data.includes('response') && !data.includes('error')) {
                const json = JSON.parse(data)
                setPage(json.artName[lang])
                document.description = json.artDesc[lang];
                setRootArr(json)
                FetchFollower(json.tweetID,json.forsearchFollower)
                getAdditional(json.spotID, json.tweetID)
                if (json.yId != '') {
                  Live(json.yId)
                } else {
                  setVideo([])
                }
              } else {
                History.push("/artists")
              }
            setLoad(false)
            setTimeout(() => setHover(false), 3000);
          });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);
    
    React.useEffect(() => {
      if (rootArr != null) {
        setPage(rootArr.artName[lang])
      }
    }, [lang]);

    const onBack = () => {
      navigator.vibrate(10);
      setLoad(true)
      setTimeout(() => History.goBack(), 500);
    }

    const AudioSpeech = (url) => {
      audioElement = new Audio(url + '?view=1');
      audioElement.addEventListener("canplay", (event) => {
        audioElement.play();
      });
    }

    React.useEffect(() => {
      const current = document.documentElement.scrollTop
      window.scrollTo(0, document.documentElement.scrollHeight);
      window.scrollTo(0, 0);
      window.scrollTo(0, current);
    }, [dark])
   
    const shareArtistProfile = (o) => {
      navigator.vibrate([50, 500,50]);
      const shareData = {
        title: 'ข้อมูลของศิลปิน: ' + document.title,
        text: o.artDesc[lang],
        url: 'https://cp-tpop.pages.dev/artist/' + o.artId
      }
      try {
        if (navigator.canShare(shareData)) {
          navigator.share(shareData);
        }
      } catch (err) {
        console.error(err.name, err.message);
      }
      
    }

    const extracturl = (link) => {
      // return atob(link.replace('?oc=5', '').replace('https://news.google.com/rss/articles/', '')).replace(/[\x00-\x1F\x7F]/g, '').replace(/.*https/, 'https').replace('Ò', '')
      return link
    }

   

    if (load) return null
    return ( 
        <>
            <Slide in={overFloat} timeout={800} direction='down' className='customfloat'>
            <div className='bg-light' style={{display: (overFloat == true ? 'block': 'none')}}>
                 {!load && rootArr != null && (
                   <Card sx={{backgroundColor: fluentColor[1]}}>
                    <CardActionArea onClick={() => {
                      window.scrollTo({top: 0, behavior: 'smooth'});
                      setScrollPosition(0)
                    }}>
                     <CardHeader
                         avatar={
                           <Avatar src={rootArr.artImg} sx={{ width: 70, height: 70 }} className='mr-3' />
                         }
                         // action={
                         //   <IconButton aria-label="settings">
                         //     <MoreVertIcon />
                         //   </IconButton>
                         // }
                         title={(<h4 className='pt-2' style={{color: '#000'}}>{rootArr.artName[lang]}</h4>)}
                         subheader={(<p style={{color: fluentColor[2]}}>{pagedetail[lang].obj.type}{lang == 'th' && 'ศิลปิน'}{capitalizeFirstLetter(rootArr.artType[lang])}{lang == 'en' && ' Artist'}</p>)}
                       />
                    </CardActionArea>
                   </Card>
                 )}
                 </div>
            </Slide>
          {
            width > 1000 && (
              <Fab size="large" sx={{position: 'fixed', zIndex: 1200, bottom: (footerref.current == null ? 10 : footerref.current.clientHeight + 10), right: 10, opacity: (hover ? 1 : 0.4)}}
              style={{backgroundColor:dark ? '#fff' : fluentColor[0], color: dark ? 'fff' : ''}}
              onMouseEnter={e => {
                setHover(true);
              }}
              onMouseLeave={e => {
                setTimeout(() => setHover(false), 1000)
              }}
              onClick={() => onBack()}>
              <ArrowBackIcon/>
            </Fab>
            )
          }
         {
          width <= 1000 && (
            <Fab size="large" sx={{position: 'fixed', zIndex: 1200, bottom: (footerref.current == null ? 10 : footerref.current.clientHeight + 10), right: 10, opacity: (hover ? 1 : 0.3)}}
            style={{backgroundColor:dark ? '#fff' : fluentColor[0], color: dark ? 'fff' : ''}}
            onClick={() => onBack()}>
             <ArrowBackIcon/>
           </Fab>
          )
        }
          <CardHeader
          action={
            rootArr != null && rootArr.sound != undefined ? (
              <IconButton onClick={() => shareArtistProfile(rootArr)}>
              <ShareIcon sx={{color: dark ? '#fff' : fluentColor[0]}} />
            </IconButton>
            ) : null
          }
          title={rootArr != null ? (<h2 style={{color: dark ? '#fff' : fluentColor[2]}}>{rootArr.artName[lang]}</h2>) : (<h2 className={dark ? 'text-light' : ''}>{lang == 'th' ? 'กำลังโหลด' : 'Loading'}</h2>)} subheader={(<p style={{color: dark ? '#fff' : fluentColor[0]}}>{pagedetail[lang].title}</p>)} />
                {
                    rootArr != null && (
                        <div className='text-center pb-5'>
                            <div className='container col-12'>
                                <div className='row d-flex justify-content-center'>
                                    <div className='col-md-3 img-zoom imgcircle' data-aos="zoom-out">
                                      <ColorExtractor getColors={colors => setColor(colors)}>
                                        <img ref={imgRef} className='imgcircle' src={rootArr.artImg} width="100%" />
                                      </ColorExtractor>
                                    </div>
                                    <div className='col-md text-left mt-3' data-aos="fade-right">

                                        <ul className="list-group">
                                          <li className="list-group-item" style={{backgroundColor: dark ? '#02012b' : '', borderColor: dark ? '#fff' : fluentColor[0]}}>
                                            <Typography variant='body1' className={dark ? 'text-light' : ''} sx={{opacity: 0.8}}>
                                              {pagedetail[lang].obj.type}{lang == 'th' && 'ศิลปิน'}{capitalizeFirstLetter(rootArr.artType[lang])}{lang == 'en' && ' Artist'}
                                            </Typography>
                                          </li>
                                          <li className="list-group-item" style={{backgroundColor: dark ? '#02012b' : '', borderColor: dark ? '#fff' : fluentColor[0]}}>
                                            <Typography variant='body1' className={dark ? 'text-light' : ''} sx={{opacity: 0.8}}>
                                              {follow != null ? pagedetail[lang].obj[followhead] : null}{follow != null ? numberWithCommas(follow) + (lang == 'th' ? ' คน' : " follower(s)") : (<Skeleton style={{backgroundColor:(fluentColor[0] || '#000') }} />)}
                                            </Typography>
                                          </li>
                                          <li className="list-group-item" style={{backgroundColor: dark ? '#02012b' : '', borderColor: dark ? '#fff' : fluentColor[0]}}>
                                            <Typography variant='body1' className={dark ? 'text-light' : ''} sx={{opacity: 0.8}}>
                                              {pagedetail[lang].obj.label}{rootArr.artlabel[lang]}
                                            </Typography>
                                          </li>
                                        </ul>
                                        <p className={'indent mt-5 mb-5' + (dark ? ' text-light' : '')}>
                                            {rootArr.artDesc[lang]}
                                        </p>
                                        <div className='border border-info pb-3 mt-2 rounded'>
                                          <div className={'row justify-content-center mt-2'  + (dark ? ' text-light ' : '')+ (width <= 800 ? ' pl-4 pr-4 text-center' : '')}>
                                            {pagedetail[lang].obj.follow}{rootArr.artName[lang]}{lang == 'en' && pagedetail[lang].obj.follow2}
                                          </div>
                                          <div className={'row justify-content-center mt-2'} style={{color:dark ? '#fff' : fluentColor[0]}}>
                                            {
                                              rootArr.following.map((itemfollow, ii) => itemfollow.en == 'facebook' ? (
                                                <FacebookIcon onClick={() => window.open('https://facebook.com/' + itemfollow.link, '_blank')} className='point' data-toggle="tooltip" data-placement="bottom" title={itemfollow[lang]} />
                                              ) : itemfollow.en == 'instagram' ? (
                                                <InstagramIcon onClick={() => window.open('https://instagram.com/' + itemfollow.link, '_blank')} className='point' data-toggle="tooltip" data-placement="bottom" title={itemfollow[lang]} />
                                              ) : itemfollow.en == 'twitter' ? (
                                                <TwitterIcon onClick={() => window.open('https://twitter.com/' + itemfollow.link, '_blank')} className='point' data-toggle="tooltip" data-placement="bottom" title={itemfollow[lang]} />
                                              ) : itemfollow.en == 'website' ? (
                                                <LanguageIcon onClick={() => window.open(itemfollow.link, '_blank')} className='point' data-toggle="tooltip" data-placement="bottom" title={itemfollow[lang]} />
                                              ) : itemfollow.en == 'youtube' ? (
                                                <YouTubeIcon onClick={() => window.open('https://youtube.com/' + itemfollow.link, '_blank')} className='point' data-toggle="tooltip" data-placement="bottom" title={itemfollow[lang]} />
                                              ) : null
                                            )}
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    Update != "null" && Currentshow != null && (
                                        <Card className='mt-2 pt-2' data-aos="zoom-in-up">
                                            <CardContent>
                                                <CardActionArea onClick={() => window.open("https://twitter.com/page/status/" + getxurl(Update[Currentshow].link,rootArr.tweetID), "_blank")}>
                                                  <CardHeader sx={{color: '#000'}} title={(lang == 'th' ? 'อัปเดตจาก ' : 'Update from  ') + rootArr.artName[lang]}  subheader={lang == 'th' ? 'ข้อมูลโดย X' : 'Powered by X and Translate.com'} />
                                                <Typography variant='h6' dangerouslySetInnerHTML={{ __html: '<blockquote><i>' + TextUpdate  + '</i></blockquote>' }}></Typography>
                                                <Typography variant='subtitle1'>{lang == 'th' ? 'อัปเดตเมื่อ ' : 'Update in '}{moment(Update[Currentshow].pubDate).local().locale(lang).format("DD MMMM YYYY HH:mm")}</Typography>
                                                </CardActionArea>
                                            </CardContent>
                                        </Card>
                                    )
                                }
                                {
                                    spot != null ? (
                                      <Grid container className='justify-content-center mt-5' spacing={spot.length > 0 ? 2 : 0}>
                                            <CardHeader sx={{color:dark ? '#fff' : fluentColor[0]}} className={'col-12'} title={lang == 'th' ? 'เพลงที่มีผู้ฟังมากที่สุดในเดือนนี้' : 'Top Song of this month'} subheader={(<p className={dark ? ' text-light ' : ''}>{lang == 'th' ? 'ให้บริการโดยสปอติฟาย' : 'Provided by Spotify'}</p>)} />
                                            {spot.length > 0 ? spot.map((itemobj, index) => (
                                                <Grid item lg={3} md={4}>
                                                <Card key={itemobj.id} data-aos="zoom-in-up" className='mt-2 text-center' sx={{backgroundColor: (dark ? '#02012b' : '')}}>
                                                        <CardContent>
                                                        <CardActionArea onClick={() => window.open(itemobj.external_urls.spotify, "_blank")}>
                                                            <CardMedia className='mb-3' src={itemobj.album.images[0].url} component='img' />
                                                            <Typography variant='h5' className={dark ? 'text-light' : ''}>
                                                                {itemobj.name}
                                                            </Typography>
                                                            <Typography className={dark ? 'text-light' : ''} variant='subtitle1'>{lang == 'th' ? 'จำหน่ายเมื่อ ' : 'Released in '}{moment(itemobj.album.release_date, "YYYY-MM-DD").locale(lang).format("DD MMMM YYYY")}</Typography>
                                                        </CardActionArea>
                                                        </CardContent>
                                                    </Card>
                                                    </Grid>
                                                )) : (
                                                  <Grid md={12}>
                                                  <Card data-aos="zoom-in-up" className='container mt-2 text-center' sx={{backgroundColor: (dark ? '#fff' : '')}}>
                                                        <CardContent className='col-12'>
                                                        {
                                                          lang == 'th' ? (
                                                            <Typography>ระบบ Spotify อยู่ระหว่างการประมวลผลศิลปิน "{rootArr.artName[lang]}" จะพร้อมให้บริการเร็วๆนี้</Typography>
                                                          ) : (
                                                            <Typography>Spotify system is currently processing of "{rootArr.artName[lang]}". Please come back later</Typography>
                                                          )
                                                        }
                                                        </CardContent>
                                                    </Card>
                                                    </Grid>
                                                )}
                                         </Grid>
                                    ) : (
                                        <Skeleton style={{backgroundColor:(fluentColor[0] || '#000') }} variant="text" sx={{ fontSize: '100px', marginTop: '1rem' }} />
                                    )
                                }
                                 {
                                    LiveVideo != null ? (
                                      <Grid className={(width > 800 ? 'container ' : '') + 'mt-5 mb-5'} spacing={2}>
                                            <CardHeader style={{color:dark ? '#fff' : fluentColor[0]}} className={'col-12'} title={pagedetail[lang].obj.livestack + rootArr.artName[lang]} subheader={(<p className={dark ? ' text-light ' : ''}>{lang == 'th' ? 'ให้บริการโดยยูทูป' : 'Provided by Youtube'}</p>)} />
                                            {LiveVideo.length > 0 ? LiveVideo.map((itemobj, index) => (
                                                <Grid item md={12} key={itemobj.id.videoId}>
                                                <Card data-aos="zoom-in-up" className='text-left mt-2' sx={{backgroundColor: (dark ? '#fff' : '')}}>
                                                        <CardContent>
                                                        <CardActionArea onClick={() => onOpen(itemobj)}>
                                                          <CardHeader title={itemobj.snippet.title} subheader={(lang == 'th' ? 'อัปโหลดเมื่อ ' : 'Uploaded in ') + moment(itemobj.snippet.publishedAt).locale(lang).format("DD MMMM YYYY HH:mm")}
                                                           avatar={
                                                            itemobj.snippet.liveBroadcastContent == 'live' ? <Avatar className='w-100' src='https://i.giphy.com/media/wvP4x67EnIhWiCztAf/giphy.webp' />
                                                            : null
                                                        } />
                                                          
                                                            <CardMedia className='mb-3' src={itemobj.snippet.thumbnails.high.url} component='img' />
                                                            <Typography variant='subtitle1'>{itemobj.snippet.description}</Typography>
                                                        </CardActionArea>
                                                        </CardContent>
                                                    </Card>
                                                    </Grid>
                                                )) : (
                                                  <Grid md={12}>
                                                  <Card data-aos="zoom-in-up" className='mt-2 text-center' sx={{backgroundColor: (dark ? '#fff' : '')}}>
                                                        <CardContent>
                                                        {
                                                          lang == 'th' ? (
                                                            <Typography>ไม่พบการถ่ายทอดสดของ{rootArr.artName[lang]}ในขณะนี้</Typography>
                                                          ) : (
                                                            <Typography>Live streaming of {rootArr.artName[lang]} is currently not found</Typography>
                                                          )
                                                        }
                                                        </CardContent>
                                                    </Card>
                                                  </Grid>
                                                )}
                                         </Grid>
                                    ) : (
                                        <Skeleton style={{backgroundColor:(fluentColor[0] || '#000') }} variant="text" sx={{ fontSize: '100px', marginTop: '1rem' }} />
                                    )
                                }
                            </div>
                            <Dialog
                              fullScreen
                              open={dialog}
                              TransitionComponent={Transition}
                              className={dark ? 'darkdialog' : ''}
                            >

                                  {
                                      obj != null && <LiveComponent dark={dark} onClose={() => onClose()} livevideoobj={obj} lang={lang} />
                                  }
                            </Dialog>
                        </div>
                    )
                }
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(ArtDetail);