import React from 'react'
import { Card, CardActionArea, CardContent, CardMedia, TextField, Zoom, FormControlLabel, Switch, Button, CardHeader, MenuItem, Backdrop } from '@mui/material';
import Swal from 'sweetalert2';
import pagedetail from '../menulist/Register.json'
import {
  useParams,
  useHistory
} from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha"

import {
    GoogleAuthProvider,
    TwitterAuthProvider,
    signInWithPopup,
    deleteUser,
    OAuthProvider
  } from "firebase/auth";
  import auth from "../fbindex";

  import { connect } from 'react-redux';
  import {
    setLoad, setLang, setDarkMode, setPage
  } from '../redux/action';

  let api;
let timerIntervalOTP;

const Registype = [
    {label: "Choose service", value: 0},
    {label: "Google Account", value: 1},
    {label: "Microsoft Account", value: 2},
    {label: "Yahoo Account", value: 3}
]

const RegisterMember = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
  const recaptchaRef = React.createRef();
  const history = useHistory()
  const [width, setRealwidth] = React.useState(window.innerWidth);
    const [ regis, setRegis ] = React.useState({
        name: "",
        email: "",
        googleid: "",
        twitterid: "",
        yahooid: ""
    })
    const [Loadint, LoadInternal] = React.useState(false);
    const [choose, setChoose] = React.useState(0);

    const [verify, setVerify] = React.useState(false);

    const UpdateParam = (key, value) => {
        let tempedit = regis
        tempedit[key] = value
        setRegis(tempedit);
    }

    function ValidateEmail(mail) 
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
        return (false)
    }

    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      sessionStorage.removeItem('artlistprevious')
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.runasp.net/tpop/status')
        .then((response) => response.text())
        .then((data) => {
          setLoad(false)
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const otpprocess = (format, obj) => {
      Swal.fire({
        title: 'Please confirm to verify Fan Space Membership Account by enter OTP. Please check your email inbox.',
        html: 'Please enter OTP in <strong></strong> second(s). OTP RefID is <b>' + format + '</b>',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off',
            placeholder: "Enter OTP which we sent to your current email",
            maxlength: 6,
            required: true
        },
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Confirm',
        denyButtonText: `Cancel`,
        timer: 180000,
        didOpen: () => {
            Swal.showLoading();
            api = setTimeout(() => {
                Swal.hideLoading();
                clearTimeout(api);
            }, 3000)
            timerIntervalOTP = setInterval(() => {
                Swal.getHtmlContainer().querySelector('strong')
                    .textContent = (Swal.getTimerLeft() / 1000)
                        .toFixed(0)
            }, 100)
        },
        preConfirm: function (val) {
            if (val.length === 0 || val.length < 6) {
                Swal.showValidationMessage(`Please enter OTP to confirm changing`)
            }
            return { otpget: val };
        },
        didClose: () => {
          if (Swal.getTimerLeft() < 100) {
            Swal.fire({
                title: 'OTP is expired',
                text: 'Please try again.',
                icon: "error"
            })
          }
        },
        willClose: () => {
          setLoad(false)
            clearTimeout(api);
            clearInterval(timerIntervalOTP);
        }
    }).then((result) => {
        if (result.isConfirmed) {
          setLoad(true)
          fetch("https://cpxdevweb.runasp.net/tpop/setverify?i=" + obj.user.uid + "&otp=" + result.value.otpget + "&refid=" + format, {
            method: 'put'
          })
            .then(x => x.json())
            .then(y => {
                if (y.verified == true) {
                  Swal.fire({
                    title: "Success",
                    icon: 'success',
                    text: 'Registration complete.',
                  }).then(() => {
                    window.location.href = '/'
                  })
                } else {
                    Swal.fire({
                        title: 'Something went wrong',
                        text: y.message,
                        icon: "error"
                    })
                }
            }).catch(() => {
                
            });
        }
      })
    }

    const RegisterAPI = (obj) => {
        fetch('https://cpxdevweb.runasp.net/tpop/registerFanSpace', {
            method :'post',
            body: JSON.stringify(regis),
            headers: {
                'Content-Type': 'application/json'
              },
        })
            .then(response => response.json())
            .then(data => {
                LoadInternal(false)
                recaptchaRef.current?.reset()
                setVerify(false)
                if (data == null) {
                  // deleteUser(obj.user)
                    Swal.fire({
                        title: "Error while login, please try again",
                        icon: 'error',
                        text: pagedetail.msg.regiserror[lang],
                      })
                } else {
                    if (data.status == 2) {
                      // deleteUser(obj.user)
                        Swal.fire({
                            title: "Error while login, please try again",
                            icon: 'error',
                            text: pagedetail.msg.regiserror[lang],
                          })
                    } else if (data.status == 1) {
                      // deleteUser(obj.user)
                        Swal.fire({
                            title: "This login authentication is already used",
                            icon: 'error',
                            text: pagedetail.msg.regisexist[lang],
                          })
                    } else {
                      LoadInternal(false)
                        otpprocess(data.otp, obj)
                    }
                }
            }).catch(() => {
              // deleteUser(obj.user)
                LoadInternal(false)
                recaptchaRef.current?.reset()
                setVerify(false)
            })
    }

    const RegisterAction = () => {
        if (ValidateEmail(regis.email) == false) {
            Swal.fire({
                title: "Email format is incorrect",
                icon: 'warning',
                text: pagedetail.msg.emailinvalid[lang],
              })
              recaptchaRef.current?.reset()
              setVerify(false)
            return;
        } else if (regis.name == '' || regis.email == '') {
            Swal.fire({
                title: "Blank value found",
                icon: 'warning',
                text: pagedetail.msg.emptyfield[lang],
              })
              recaptchaRef.current?.reset()
              setVerify(false)
            return;
        } else if (choose == 0) {
            Swal.fire({
                title: "Fan Space Platform need at least one Account service to make Seamless Login.",
                icon: 'warning',
                text: pagedetail.msg.chooseservice[lang],
              })
              recaptchaRef.current?.reset()
              setVerify(false)
            return;
        }
        let provider = null;
    switch (choose) {
      case 1:
        provider = new GoogleAuthProvider();
        break;
      case 2:
        provider = new OAuthProvider("microsoft.com");
        break;
      case 3:
        provider = new OAuthProvider("yahoo.com");
        break;
      default:
        alert("Login fail");
        return;
    }
    LoadInternal(true)
    signInWithPopup(auth, provider)
      .then((result) => {
        switch (choose) {
            case 1:
              UpdateParam('googleid', result.user.uid);
              break;
            case 2:
              UpdateParam('twitterid', result.user.uid);
              break;
            case 3:
              UpdateParam('yahooid', result.user.uid);
              break;
            default:
              return;
          }
          RegisterAPI(result)    
      })
      .catch((error) => {
        // Handle error.
        LoadInternal(false)
        recaptchaRef.current?.reset()
        setVerify(false)
        Swal.fire({
          title: "Account Link fail",
          icon: 'error',
          text: 'Seamless Login required to use one of these service to make login process.',
        })
      });
    }

    React.useEffect(() => {
      sessionStorage.removeItem('artlistprevious')
      if (lang == 'th') {
        setPage('สมัครสมาชิก Fan Space Membership')
      } else {
        setPage('Fan Space Membership Registration')
      }
    },[])

    return (
      <Card className='bg-light'>
        <div className='mt-3 container'>
            <CardHeader title={pagedetail.head[lang]} subheader={pagedetail.sub[lang]} />
            <div className='col-md-12'>
              <TextField
                 label={pagedetail.nameField[lang]}
                 className="m-3"
                 fullWidth={true}
                 onChange={(e) => UpdateParam('name',e.target.value)}
                 ></TextField>
            <TextField
                 label={pagedetail.emailField[lang]}
                 className="m-3"
                 type='email'
                 fullWidth={true}
                 onChange={(e) => UpdateParam('email',e.target.value)}
                 ></TextField>
                   <TextField
                        select
                        label={pagedetail.loginOption[lang]}
                        value={choose}
                        fullWidth={true}
                        className="m-3"
                        onChange={(e) => setChoose(e.target.value)}
                        >
                     {Registype.map((option) => (
                         <MenuItem key={option.value} value={option.value}>
                         {option.label}
                         </MenuItem>
                     ))}
              </TextField>
                 <div className='justify-content-center row'>
                    <p className='text-info'>{pagedetail.loginNotes[lang]}</p>
                 </div>
                 <div className='justify-content-center row'>
                 <ReCAPTCHA sitekey='6LfWaT0kAAAAAJ85uQ2mGp69o8VOSOSyy4bMUhze' onError={() => setVerify(false)} onExpired={() => {
                      Swal.fire({
                        title: "Verification expired",
                        icon: 'error',
                        text: lang == 'th' ? 'เซสชั่นการยืนยันตัวตนหมดอายุ กรุณาลองใหม่อีกครั้ง' : 'Bot Verify session is expired. Please try again',
                      })
                      setVerify(false)
                     }} hl={lang} ref={recaptchaRef} onChange={() => setVerify(true)} />
                 </div>
                 <div className='justify-content-center row mb-4 mt-2'>
                     <Button color='primary' variant='contained' onClick={() => RegisterAction()} disabled={!verify}>{pagedetail.btn[lang]}</Button>
                 </div>
            </div>
            <Backdrop  sx={{ zIndex: 1500, position: 'fixed' }} open={Loadint}>
            <img src="https://d3hhrps04devi8.cloudfront.net/main/tpopplay-load.svg" width="50px" />
        </Backdrop>
        </div>
        </Card>
    )
}

const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(RegisterMember);