import React from 'react'
import {
  useParams,
  useHistory
} from "react-router-dom";
import { CardHeader, Card, Backdrop, Typography,Skeleton, ListItem, ListItemButton, ListItemText } from '@mui/material';

import NewsCom from './newscomponent/news'
import moment from 'moment'

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

import pagedetail from '../menulist/News.json'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const blocked = [
  "TpopConcertFest"
]

const News = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage, login}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [rootArr, setRootArr] = React.useState(null);
    const History = useHistory();
  
   
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('ข่าวสาร T-POP')
      } else {
        setPage('T-POP News')
      }
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      if (lang == 'th') {
        fetch('https://cpxdevweb.runasp.net/tpop/newslist', {
          method: 'post'
        })
          .then((response) => response.json())
          .then((data) => {
            if (data != null) {
              const set = {
                n: data.n.sort((a, b) => (new Date(b.pubDate) - new Date(a.pubDate))),
                x: data.x
              }
              setRootArr(set)
            }
            setLoad(false)
          });
      } else {
        fetch('https://cpxdevweb.runasp.net/tpop/status')
        .then((response) => response.text())
        .then((data) => {
          setLoad(false)
        });
      }
     
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

 const checkedpointfromx = (name) => {
  for(let i = 0; i < blocked.length; i++) {
    if (name.includes(blocked[i])) {
      return false;
    }
  }
  return true;
 }
    
    React.useEffect(() => {
      if (load == false && lang == 'th' && rootArr == null) {
        setLoad(true)
      setTimeout(() => {
        fetch('https://cpxdevweb.runasp.net/tpop/newslist', {
          method: 'post'
        })
          .then((response) => response.json())
          .then((data) => {
            if (data != null) {
              const set = {
                n: data.n.sort((a, b) => (new Date(b.pubDate) - new Date(a.pubDate))),
                x: []
              }
              setRootArr(set)
            }
            setLoad(false)
          });
      }, 700)
      }
    }, [lang]);

    function getxurl(url) {
      if (url.includes('nitter.esmailelbob.xyz')) {
        return url.replace("http://nitter.esmailelbob.xyz/todayplayth/status/", "").replace("#m", "")
      }
      if (url.includes('nitter.mint.lgbt')) {
          return url.replace("https://nitter.mint.lgbt/todayplayth/status/", "").replace("#m", "")
      }
      if (url.includes('nitter.tux.pizza')) {
          return url.replace("https://nitter.tux.pizza/todayplayth/status/", "").replace("#m", "")
      }
      if (url.includes('nitter.no-logs.com')) {
          return url.replace("http://nitter.no-logs.com/todayplayth/status/", "").replace("#m", "")
      }
      return url;
  }

    React.useEffect(() => {
      const current = document.documentElement.scrollTop
      window.scrollTo(0, document.documentElement.scrollHeight);
      window.scrollTo(0, 0);
      window.scrollTo(0, current);
    }, [dark])

    if (load) return null

if (lang == 'en') {
 return (
  <Backdrop
  sx={{ zIndex: -1, position: 'fixed', backgroundColor: (dark ? "#02012b":"#fff") }}
  open={true}
  className={(dark ? 'text-light' : '') + (width < 800 ? ' pt-5' : '')}
  >
  T-POP News is avaliable in Thai language only.
</Backdrop>
 )
}


    return ( 
        <>
          <div className='text-center'>
            {
              dark ? (
            <Card className='text-left'>
               <CardHeader title={lang == 'th' ? 'ข่าวสาร T-POP' : 'T-POP News'} subheader={lang == 'th' ? 'ข่าวสารล่าสุดเกี่ยวกับวงการทีป็อป' : 'Fresh news about T-POP. Provided by Google News and Google Translate'} />
            </Card>
              ) : (
              <div className='text-left'>
                <CardHeader title={lang == 'th' ? 'ข่าวสาร T-POP' : 'T-POP News'} subheader={lang == 'th' ? 'ข่าวสารล่าสุดเกี่ยวกับวงการทีป็อป' : 'Fresh news about T-POP. Provided by Google News and Google Translate'} />
            </div>
              )
            }

          {rootArr != null && login != null && (
                 <div className='container border border-info rounded mt-3 col-12'>
                 <div className='text-left'>
                <CardHeader className={dark ? 'text-light text-bold' : 'text-bold'} title={lang == 'th' ? 'T-POP Exclusive News' : ''} subheader={<p className={dark ? 'text-light' : ''}>{lang == 'th' ? 'แหล่งที่มา: Today Play by Workpoint Today' : ''}</p>} />
            </div>
            <div className='container d-flex justify-content-center'>
       <iframe src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftodayplayth&tabs=timeline&width=500&small_header=false&adapt_container_width=true&height=700&hide_cover=false&show_facepile=true"} width="500px" height="500px" frameborder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
       </div>
               </div>
                )}

            <div className='container mt-3 col-12'>
              <div className='row d-flex justify-content-center'>
              {rootArr != null && rootArr.n.map((item, i) => (
                 <NewsCom item={item} dark={dark} lang={lang} i={i} />
                ))}
              </div>
            </div>

          </div>
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(News);
