import React from 'react'
import pagedetail from '../menulist/StoryPlay.json'
import Typography from '@mui/material/Typography';
import { CardHeader, CardMedia, Card, Slide, Dialog, ListItem, ListItemButton, ListItemText, AppBar, CardActionArea, Avatar, ListItemAvatar, Fab, Zoom, Box, Button, makeStyles } from '@mui/material';
import {
  useParams,
  useHistory
} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'
import { TransitionProps } from '@mui/material/transitions';
import Stories from 'stories-react';
import 'stories-react/dist/index.css';

import { connect } from 'react-redux';
import {
  setLoad, setLang, setDarkMode, setPage
} from '../redux/action';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getRandomData = (array, count) => {
  const result = [];
  const arrayLength = array.length;

  if (count > arrayLength) {
    console.error('Count is greater than the array length');
    return result;
  }

  const indices = new Set();

  while (indices.size < count) {
    const randomIndex = Math.floor(Math.random() * arrayLength);
    indices.add(randomIndex);
  }

  indices.forEach((index) => {
    result.push(array[index]);
  });

  return result;
};


const Playlist = ({dark, load, lang, currentPage,
  setLoad, setDark, setLang, setPage}) => {
    const [width, setRealwidth] = React.useState(window.innerWidth);
    const [rootArr, setRootArr] = React.useState([]);

    const [sample, setSample] = React.useState([]);

    const [story, setStory] = React.useState(false);

    const [diaOrigin, setOrigin] = React.useState(null);
    const [dia, setDialog] = React.useState(null);
    
    const [instantview, setInstantview] = React.useState([]);
    const History = useHistory();
    const dialogref = React.useRef(null)
    const classes = {
      avatar: {
        border: '4px solid',
        borderColor: 'red',
        width: 65,
        height: 65
      }
    };
    const removefonttag = (data) => {
        var div = document.createElement('div');

        // assing your HTML to div's innerHTML
        div.innerHTML = data;

        // get all <a> elements from div
        var elements = div.getElementsByTagName('font');

        // remove all <a> elements
        while (elements[0])
        elements[0].parentNode.removeChild(elements[0])

        // get div's innerHTML into a new variable
        var repl = div.innerHTML;
        return repl
    }
    
    React.useEffect(() => {
      function handleWindowResize() {
        setRealwidth(window.innerWidth);
      }
      if (lang == 'th') {
        setPage('สตอรี่เพลย์ลิสต์')
      } else {
        setPage('T-POP Story Playlist')
      }
     
      window.addEventListener('resize', handleWindowResize);
      setLoad(true)
      fetch('https://cpxdevweb.runasp.net/tpop/getstoryplaylist', {
        method: 'get'
      })
        .then((response) => response.json())
        .then((data) => {
          data.map((item, i) => {
            if (item.isLaunch == true && item.isLatest == true || (item.isLatest == false && i == 1 && data[0].isLaunch == false)) {
              setSample(getRandomData(item.response.tracks.items, 10))
            }
          })
          setRootArr(data)
          setLoad(false)
          setTimeout(() => {
            fetch('https://cpxdevweb.runasp.net/tpop/instantlistartists', {
                method: 'post'
            })
                .then((response) => response.json())
                .then((data) => {
                if (data.length > 0) {
                    setInstantview(data)
                }
                });
          }, 300);
        });
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    const getLink = (spIdfrommain) => {
        let link = ""
        if (instantview.length > 0 && instantview.filter(x => x.spotID == spIdfrommain).length > 0) {
            link = instantview.filter(x => x.spotID == spIdfrommain)[0].artId
        }
        return link
    }

    const changep = (artid) => {
        setDialog(null)
        setLoad(true)
        setTimeout(() => History.push('/artist/' + artid), 700)
      }

      const GetSeemore = ({link, artists}) => {
        return (
          <d className={dark ? 'darkdialog' : ''}>
          <iframe data-aos='zoom-in-down' style={{borderRadius: '12px', marginTop: 60}} src={"https://open.spotify.com/embed/" + link.replace('https://open.spotify.com/','') + "?utm_source=generator"} width="100%" height="50%" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          <p className={(dark ? 'text-light' : '') + ' p-3'}>{lang == 'th' ? 'ข้อมูลศิลปินที่เกี่ยวข้อง (ศิลปินที่มีข้อมูลในระบบจะขึ้นสีฟ้าหรือสีน้ำเงิน)' : 'Related artist(s) (You will see in blue color text when that artist included in platform system)'}</p>
          <div className='container'>
          {artists.map((art, i) => (<a key={art.id} onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: dark ? '#0cd8f7' : '#2121ff', cursor: 'pointer'} : {color: dark ? '#fff' : '#000'}}>{(i == 0 ? '' : ', ') + art.name}</a>))}
          </div>
          </d>
        )
      }

      const openDialog = (item) => {
        setOrigin(item)
        let tempitem = item.response.tracks.items
        let data = []


        tempitem.map((tracks) => {

            data.push({
              type: 'image',
              url: tracks.track.album.images[0].url,
              duration: 10000,
              header: (
                <Card
                  data-aos='zoom-in-right'
                  style={{
                    backgroundColor: item.recommend.id == tracks.track.id ? (dark ? '#7d6025' : '#BF953F') : (dark ? "#02012b" : "#fff"),
                    padding: '8px',
                    marginLeft: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '70%',
                  }}
                >
                  <div
                    style={{
                      marginLeft: '3px',
                    }}
                  >
                    <h2
                      style={{
                        color: dark ? 'white' : 'black',
                        margin: 0,
                        marginLeft: '3px',
                      }}
                    >
                      {(<h6 className={dark ? 'text-light' : ''} variant='h6'>{tracks.track.name}</h6>)}
                    </h2>
                    <div
                      style={{
                        margin: 0,
                        marginLeft: '3px',
                      }}
                    >
                      {tracks.track.artists.map((art, i) => (<a key={art.id} style={{color: dark ? '#bababa' : '#3b3b3b'}}>{art.name + (i == tracks.track.artists.length - 1 ? '' : ', ')}</a>))}
                    </div>
                  </div>
                </Card>
              ),
              onSeeMoreClick: () => {
                var h = setInterval(() => {
                  if (document.querySelector('div[class*="SeeMoreComponent-styles"]') != null) {
                    clearInterval(h)
                    document.querySelector('button[class*="SeeMoreComponent-styles_closeIcon"]').style.color = dark ? "#fff" : ''
                  }
                }, 1);
              },
              seeMore: (
                <Zoom in={true} timeout={800} style={{ transitionDelay: `2500ms` }}>
                <Card>
                <CardActionArea className={dark ? 'text-light p-2' : 'p-2'}>
                  {lang == 'th' ? 'คลิกเพื่อดูรายละเอียดเพิ่มเติม': 'Click here to see more'}
                </CardActionArea>
                </Card>
                </Zoom>
              ),
              seeMoreComponent: <GetSeemore link={tracks.track.external_urls.spotify} artists={tracks.track.artists} />
            })
        })
        setDialog(data)
      }
    
    if (load) return null
    return ( 
        <>
        <CardHeader title={(<h3 className={dark ? 'text-light' : ''}>{pagedetail[lang].title}</h3>)} subheader={(<p className={dark ? 'text-light' : ''}>{pagedetail[lang].desc}</p>)} />

        <Dialog
            fullScreen
            open={dia != null ?true : false}
            onClose={()=> setDialog(null)}
            TransitionComponent={Transition}
            className={dark ? 'darkdialog' : ''}
          > 
            {dia != null && (
            <Fab size={width > 800 ? "large" : "small"} color='primary' sx={{color: '#fff', position: 'fixed', zIndex: 3, top: 15, right :7}}
            onClick={() => setDialog(null)}>
             <CloseIcon/>
           </Fab>
          )
        }
        {dia != null && (
            <Box
            role="presentation"
            ref={dialogref}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Stories onAllStoriesEnd={() => setDialog(null)} width="400px" height={window.innerHeight} stories={dia} />
            </div>
            
            
            {/* {
                dia.response.tracks.items.map((tracks) => (
                    <ListItem className='col-md-12' disablePadding style={dia.recommend.id == tracks.track.id ? {borderLeft: '8px solid #BF953F', zIndex: 5, position: 'sticky'} : null}>
                        <ListItemButton>
                        <ListItemAvatar onClick={() => window.open(tracks.track.external_urls.spotify, '_target')}>
                            <Avatar sx={{ width: 100, height: 100 }} variant='rounded' src={tracks.track.album.images[0].url} />
                        </ListItemAvatar>
                        <ListItemText className='pl-3' primary={
                            (<Typography className={dark ? 'text-light' : ''} variant='h6'>{tracks.track.name}</Typography>)}
                            secondary={(<p>
                                {tracks.track.artists.map((art, i) => (<a onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: dark ? '#61dafb' : '#0a05f2'} : {color: dark ? '#fff' : 'rgb(46, 46, 46)'}}>{art.name + (i == tracks.track.artists.length - 1 ? '' : ', ')}</a>))}
                                </p>
                            )} 
                        />
                        </ListItemButton>
                    </ListItem>
                ))
            } */}
          
          </Box>
      )}
          </Dialog>
          
          <div>
            <div className='container col-12'>
              <div className='row'>
              {rootArr.map((item, i) => item.isLaunch == true ? (
                <Card className='col-sm-12 pb-3 mt-3' style={item.isLatest == true ? {borderLeft: '8px solid #BF953F'} : {}}>
                    <CardActionArea target='_blank'>
                        <CardHeader 
                         avatar={
                          item.isLatest == true || (item.isLatest == false && i == 1 && rootArr[0].isLaunch == false) ? (
                          <Avatar onClick={() => {
                            setStory(true)
                            openDialog(item)
                          }} sx={!story ? classes.avatar : {width: 67,  height: 67}} src={item.response.images[0].url}></Avatar>
                        ) : null}
                        title={<h4 onClick={() => window.open(item.response.external_urls.spotify, '_target')} className='pt-3'>{'T-POP Story Episode ' + item.episode}</h4>} subheader={(<p onClick={() => window.open(item.response.external_urls.spotify, '_target')} dangerouslySetInnerHTML={{ __html: removefonttag(item.response.description)}} />)} />
                    </CardActionArea>
                     <div className='container pb-4'>
                     {
                            item.isLatest == true || (item.isLatest == false && i == 1 && rootArr[0].isLaunch == false)? (
                                <div className='row'>
                                  <h4 className='col-12 text-center mb-3'>{lang == 'th' ? 'เพลงแนะนำ' : 'Suggested Songs'}</h4>
                                    {
                                        sample.map((tracks) => (
                                            <ListItem data-aos="zoom-in" className='col-md-6' disablePadding>
                                                <ListItemButton>
                                                <ListItemAvatar onClick={() => window.open(tracks.track.external_urls.spotify, '_target')}>
                                                    <Avatar sx={{ width: 100, height: 100 }} variant='rounded' src={tracks.track.album.images[0].url} />
                                                </ListItemAvatar>
                                                <ListItemText className='pl-3' primary={
                                                    (<Typography className={dark ? 'lightlink' : ''} variant='h6'>{tracks.track.name}</Typography>)}
                                                    secondary={(<p>
                                                        {tracks.track.artists.map((art, i) => (<a onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: '#0a05f2'} : {}}>{art.name + (i == tracks.track.artists.length - 1 ? '' : ', ')}</a>))}
                                                        </p>
                                                    )} 
                                                />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    }
                                    {
                                        lang == 'th' ?(
                                            <p className='col-12 mt-3'>หมายเหตุ: <b style={{color: '#0a05f2'}}>ตัวอักษรสีฟ้าเข้ม</b>บนชื่อศิลปินหมายความว่าพบข้อมูลรายละเอียดศิลปินในระบบฐานข้อมูลของเรา คลิกที่ชื่อศิลปินเพื่อดูข้อมูล</p>
                                        ) : (
                                            <p className='col-12 mt-3'>Notes: <b style={{color: '#0a05f2'}}>Blue Navy text color</b> on artist name means found this artist description in our platform database. Click it to view</p>
                                        )
                                    }
                                </div>
                            ) :(
                                <div className='row col-md-12 mt-3'>
                                    <div className='col-md-3'>
                                        <CardMedia component='img' src={item.recommend.album.images[0].url} />
                                    </div>
                                    <div className='col-md'>
                                        <Typography variant='h4'>{lang == 'th'? 'เพลงแนะนำ' : 'Recommend Song'}: {item.recommend.name}</Typography>
                                        <Typography variant='body1'>{lang == 'th'? 'ศิลปิน' : 'Artist(s)'}: {item.recommend.artists.map((art, i) => (<a onClick={() => getLink(art.id) != '' ? changep(getLink(art.id)) : null} style={getLink(art.id) != '' ? {color: '#0a05f2'} : {}} className={getLink(art.id) != '' ? 'point':''}>{art.name + (i == item.recommend.artists.length - 1 ? '' : ', ')}</a>))}</Typography>
                                        <hr />
                                        <CardActionArea onClick={() => openDialog(item)}>
                                            <Typography variant='h6'>{lang == 'th'? 'และอีกกว่า 19 เพลงในเพลย์ลิสต์นี้ คลิกที่นี่เพื่อดูรายการเพลงในเพลย์ลิสต์นี้' : 'And more 19 songs in this playlist. Click here to see all songs of this playlist'}</Typography>
                                        </CardActionArea>
                                    </div>
                                    {
                                        lang == 'th' ?(
                                            <p className='col-12 mt-3'>หมายเหตุ: <b style={{color: '#0a05f2'}}>ตัวอักษรสีฟ้าเข้ม</b>บนชื่อศิลปินหมายความว่าพบข้อมูลรายละเอียดศิลปินในระบบฐานข้อมูลของเรา คลิกที่ชื่อศิลปินเพื่อดูข้อมูล</p>
                                        ) : (
                                            <p className='col-12 mt-3'>Notes: <b style={{color: '#0a05f2'}}>Blue Navy text color</b> on artist name means found this artist description in our platform database. Click it to view</p>
                                        )
                                    }
                                </div>
                            )
                        }
                     </div>
                    </Card>
                ) : (
                    <Card className='col-sm-12' style={item.isLatest == true ? {borderLeft: '8px double #BF953F'} : {}}>
                        <CardHeader title={'T-POP Story Episode ' + item.episode} subheader={lang == 'th' ? 'เพลย์ลิสต์นี้จะพร้อมให้รับฟังได้ เร็วๆนี้' : 'This playlist will be avaliable publicly soon'} />
                    </Card>
                ))}
              </div>
            </div>
          </div>
        </>
     );
}
 
const mapStateToProps = (state) => ({
  load: state.load,
  dark: state.dark,
  lang: state.lang,
  currentPage: state.currentPage
});
const mapDispatchToProps = (dispatch) => ({
  setLoad: (val) => dispatch(setLoad(val)),
  setDark: (val) => dispatch(setDarkMode(val)),
  setLang: (val) => dispatch(setLang(val)),
  setPage: (val) => dispatch(setPage(val))
});
export default connect(mapStateToProps, mapDispatchToProps)(Playlist);